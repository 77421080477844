<template>
  <v-dialog
    v-model="showCarInfo"
    max-width="771"
    rounded="lg"
    scrollable
  >
    <v-card class="rimconfig-car-info">
      <v-card-title>
        <h5 class="text">
          {{ $t('langkey.details') }}
        </h5>
        <v-btn
          icon
          plain
          class="close__button"
          @click="hide"
        >
          <icon-close size="18" />
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 582px;">
        <div class="upper">
          <div class="car-image-wrapper">
            <div class="test">
              <img
                :src="carImageUrl"
                class="car-image"
              >
            </div>
          </div>
          <div class="car-details-wrapper">
            <ul>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-type') }}:</span>
                <span class="value">{{ carData.type }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-hsn') }}:</span>
                <span class="value">{{ `${carData.hsn}/${carData.tsn}/${carData.vsn}` }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-power') }}:</span>
                <span class="value">{{ `${carData.power}/${carData.systemPower}` }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-abe') }}:</span>
                <span class="value">{{ carData.typeApproval }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-bolt') }}:</span>
                <span class="value">{{ carData.boltPattern }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-pin') }}:</span>
                <span class="value">{{ carData.innerBoltPatternFront | dot2comma }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-year') }}:</span>
                <span class="value">{{ getProductionYear }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-displacement') }}:</span>
                <span class="value">{{ carData.ccm }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-cylingder') }}:</span>
                <span class="value">{{ carData.cylinder }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-fuel') }}:</span>
                <span class="value">{{ $t(`langkey.${carData.fuelCode}`) }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-drive-axis') }}:</span>
                <span class="value">{{ carData.driveAxis }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-wheel-front-axis') }} (kg):</span>
                <span
                  v-if="carData.axisLoadFront"
                  class="value"
                >{{ carData.axisLoadFront.substring(1) }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-wheel-rear-axis') }} (kg):</span>
                <span
                  v-if="carData.axisLoadRear"
                  class="value"
                >{{ carData.axisLoadRear.substring(1) }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-max-speed') }} (km/h):</span>
                <span class="value">{{ carData.speedMax }}</span>
              </li>
              <li class="car-detail">
                <span class="label">{{ $t('langkey.car-dialog-tpms-info') }}</span>
                <span
                  v-if="carData.tpms === 0"
                  class="value"
                >{{ $t('langkey.no-tpms') }}</span>
                <span
                  v-else-if="carData.tpms === 1"
                  class="value"
                >{{ $t('langkey.tpms-active') }}</span>
                <span
                  v-else-if="carData.tpms === 2"
                  class="value"
                >{{ $t('langkey.tpms-passive-optional') }}</span>
              </li>
            </ul>
          </div>
        </div>
        <v-data-table
          class="primary-style"
          :headers="carCocInfoTableFields"
          :items="cocData"
          :loading="isLoading"
          :no-data-text="$t('langkey.no-data')"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in carCocInfoTableFields"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="icon"
          @click="onPrint"
        >
          {{ $t('langkey.car-dialog-print') }}
          <icon-pdf
            color="var(--theme-black)"
            bg-color="var(--theme-white)"
          />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-car-info__dialog.scss";
</style>

<script>
import IconPdf from '@/components/icons/IconPdf';
import { carCocInfoTableFields } from '@/pages/rims-config/configurator/_carTag/configs';
import { mapState } from 'vuex';
import _ from 'lodash';
import { getTextAfterWord, getTextBeforeWord } from '@/utils';

export default {
  components: {
    IconPdf,
  },
  props: {
    imageCdn: {
      type: String,
      default: 'https://cdn.jfnet.de',
    },
    imagePrefix: {
      type: String,
      default: 'cars360',
    },
    angle: {
      type: [Number, String],
      default: 90,
    },
    carColor: {
      type: String,
      default: '',
    },
    carSuspension: {
      type: [Number, String],
      default: 4,
    },
    imageResolution: {
      type: String,
      default: '770x300',
    },
    imageSuffix: {
      type: String,
      default: 'cc-t-xxxxxx.png',
    },
  },
  data() {
    return {
      showCarInfo: false,
      isLoading: false,
      carImageUrl: '',
    };
  },
  computed: {
    ...mapState({
      carData: (state) => state.rimconfigCarConfigurator.carData,
      carImage: (state) => state.rimconfigCarConfigurator.carImage,
      selectedWheel: (state) => state.rimconfigCarConfigurator.selectedWheel,
      carCocData: (state) => state.rimconfigCarConfigurator.carCocData,
    }),
    getCarTag() {
      return this.$route.params.carTag;
    },
    carCocInfoTableFields() {
      return carCocInfoTableFields;
    },
    getProductionYear() {
      if (!_.isEmpty(this.carData)) {
        return this.carData.productionFrom.split('-')[0];
      } else {
        return '';
      }
    },
    getImageCarLabel() {
      if (!_.isEmpty(this.carImage)) {
        const imageCarLabel = getTextAfterWord('/cars360/', this.carImage.urlTemplate).split('-')[0];
        return imageCarLabel;
      } else {
        return '';
      }
    },
    getWheelId() {
      if (!_.isEmpty(this.carImage)) {
        const wheelId = this.carImage.urlTemplate.split('-')[4];
        return wheelId;
      } else {
        return '';
      }
    },
    cocData() {
      return this.carCocData.map((item) => {
        return {
          frontTyre: `${item.tyreWidthFront}/${item.tyreHeightFront}${item.tyreRadialFront}${item.diameterFront}
            ${item.tyreLoadFront} ${item.tyreSpeedFront}`,
          frontWheel: `${this.$options.filters.wheelWidth(item.wheelWidthFront)} x ${item.diameterFront} ${this.$t('langkey.configurator-prop-offset')} ${item.wheelOffsetFront}`,
          rearTyre: !item.tyreWidthRear ? this.$t('langkey.coc-rear-same-front') : `${item.tyreWidthRear}/${item.tyreHeightRear}${item.tyreRadialRear}${item.diameterRear}
            ${item.tyreLoadRear} ${item.tyreSpeedRear}`,
          rearWheel: !item.tyreWidthRear ? this.$t('langkey.coc-rear-same-front') : 
            `${this.$options.filters.wheelWidth(item.wheelWidthRear)} x ${item.diameterRear} ${this.$t('langkey.configurator-prop-offset')} ${item.wheelOffsetRear}`,
        };
      });
    },
  },
  watch: {
    showCarInfo(val) {
      this.$emit('show', val);
    },
  },
  methods: {
    show() {
      this.showCarInfo = true;

      this.isLoading = true;
      this.$store.dispatch('rimconfigCarConfigurator/getCarCoc', [this.getCarTag])
        .then(() => {
          this.isLoading = false;
          this.setCarImage();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    hide() {
      this.showCarInfo = false;
    },
    setCarImage() {
      if (!_.isEmpty(this.selectedWheel)) {
        const wheelSize = this.selectedWheel.diameter;
        this.carImageUrl = `${getTextBeforeWord('/cars360/', this.carImage.urlTemplate)}/cars360/${this.getImageCarLabel}-${this.angle}-${this.carColor}-${this.carSuspension}-${this.getWheelId}-${wheelSize}/${this.imageResolution}-${this.imageSuffix}`;
      }
    },
    onPrint() {
      this.$emit('load-pdf');
    },
  },
};
</script>