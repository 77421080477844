<template>
  <div class="rimconfig-wheel-card-row">
    <rimconfig-wheel-card
      v-for="(item, itemIndex) in source.items"
      :key="`rimconfigWheelOverviewCard${source.id}${itemIndex}${item.design}`"
      :wheel-data="item"
      @click-wheel="onClickWheel"
      @click-diameter="onClickDiameter"
      @click-info="onShowWheelInfo"
      @click-cart="onClickCart"
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-card-row.scss";
</style>

<script>
import RimconfigWheelCard from '@/components/RimconfigWheelCard';
import { windowSize } from '@/utils/mixins';

export default {
  // eslint-disable-next-line
  name: 'keep-state-item',
  components: {
    RimconfigWheelCard,
  },
  mixins: [windowSize],
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClickWheel(minimumDiameterWheels) {
      this.dispatch('keep-state', 'onClickWheel', this.source.id, minimumDiameterWheels);
    },
    onClickDiameter(diameterWheels) {
      this.dispatch('keep-state', 'onClickDiameter', this.source.id, diameterWheels);
    },
    onShowWheelInfo(wheelData) {
      this.dispatch('keep-state', 'onShowWheelInfo', this.source.id, wheelData);
    },
    onClickCart(wheelData) {
      this.dispatch('keep-state', 'onClickCart', this.source.id, wheelData);
    },
  },
};
</script>