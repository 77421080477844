<template>
  <div class="rims-config_carTag">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
      @on-click-wheels="onClickWheels"
    />
    <fullscreen v-model="isFullscreen">
      <section class="car-display__section">
        <rimconfig-car-simulator
          :is-loading="isLoading"
          :is-fullscreen="isFullscreen"
          @on-click-info="onShowCarInfo"
          @on-click-zoom="onZoomCar"
          @on-click-exit="onExitZoom"
          @on-select-color="onSelectColor"
          @on-select-suspension="onSelectSuspension"
          @on-select-background="onSelectBackground"
          @loading-car-threesixty="onLoadingCarThreesixty"
          @frame-change="onFrameChange"
        />

        <template v-if="!hideBg">
          <transition name="fade">
            <v-overlay
              v-if="isLoading360Bg || isLoadingCar360"
              :z-index="19"
              :opacity="0.7"
              absolute
              color="#fff"
            />
            <img
              v-else
              :class="['car-displayer-bg', isFullscreen && 'is-fullscreen']"
              :src="carBgImages[carBgFrame].src"
            >
          </transition>
          <div
            v-if="!isFullscreen"
            :style="{
              backgroundImage: `url(${selectedBgSrc})`
            }"
            class="car-displayer-bg-overlay"
          />
        </template>
      </section>
    </fullscreen>

    <transition
      name="page"
      mode="out-in"
    >
      <rimconfig-wheel-detail
        v-if="showWheelDetail"
        :has-tpms="hasTpms"
        @get-car-image="getCarImage"
      />
      <rimconfig-wheel-overview
        v-else
        ref="rimconfigWheelOverview"
        @on-select-wheel="onSelectWheel"
        @click-wheel-detail="toWheelDetail"
        @click-cart="toWheelDetail"
      />
    </transition>

    <rimconfig-car-info-dialog
      ref="rimconfigCarInfoRef"
      :car-color="carColor"
      :car-suspension="carSuspension"
      @load-pdf="onLoadPdfOffer"
      @show="onCarInfoShow"
    />

    <vue-html2pdf
      v-if="!isEmptySelectedWheel && isCarInfoShow"
      ref="html2Pdf"
      style="display: none;"
      :filename="`${$t('langkey.details')} ${getCarTag}`"
      :html-to-pdf-options="carInfoPdfOption"
      @hasDownloaded="hasDownloaded($event)"
    >
      <template #pdf-content>
        <rimconfig-car-info-pdf
          :car-color="carColor"
          :car-suspension="carSuspension"
        />
      </template>
    </vue-html2pdf>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/rims-config_carTag.scss";
</style>

<script>
import VueHtml2pdf from '@/components/pdf/VueHtml2pdf';
import RimconfigCarInfoPdf from '@/components/pdf/RimconfigCarInfoPdf';
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import RimconfigCarSimulator from '@/components/RimconfigCarSimulator';
import RimconfigWheelOverview from '@/components/RimconfigWheelOverview';
import RimconfigWheelDetail from '@/components/RimconfigWheelDetail';
import RimconfigCarInfoDialog from '@/components/RimconfigCarInfoDialog';
import {
  breadcrumbItemsWheels,
  breadcrumbItemsWheelsNoState,
  breadcrumbItemsWheelsDetails,
  breadcrumbItemsWheelsDetailsNoState,
  carSimulator360Bg,
} from './configs.js';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import { optimisticLoadImg } from '@/utils/preloadImage.js';

export default {
  components: {
    VueHtml2pdf,
    RimconfigCarInfoPdf,
    RimconfigBreadcrumb,
    RimconfigCarSimulator,
    RimconfigWheelOverview,
    RimconfigWheelDetail,
    RimconfigCarInfoDialog,
  },
  data() {
    return {
      showWheelDetail: false,
      isLoading: true,
      isLoading360Bg: true,
      isLoadingCar360: false,
      isCarInfoShow: false,
      carColor: '',
      carSuspension: 0,
      isFullscreen: false,
      selectedBgSrc: '',
      selectedBgName: '',
      carBgImages: [],
      carBgFrame: 0,
      hideBg: true,
      hasTpms: false,
    };
  },
  computed: {
    ...mapState({
      selectedWheel: (state) => state.rimconfigCarConfigurator.selectedWheel,
      versionsConfigurator: (state) => state.rimconfigCarSelector.versions,
      carImage: (state) => state.rimconfigCarConfigurator.carImage,
    }),
    ...mapGetters({
      getGroupedCarWheelsByDiameter: 'rimconfigCarConfigurator/getGroupedCarWheelsByDiameter',
    }),
    breadcrumbItems() {
      if (this.$route.query.wheelDetail && this.versionsConfigurator.length === 0) {
        return breadcrumbItemsWheelsDetailsNoState;
      }
      if (this.$route.query.wheelDetail) {
        return breadcrumbItemsWheelsDetails;
      }
      if (this.versionsConfigurator.length === 0) {
        return breadcrumbItemsWheelsNoState;
      }
      return breadcrumbItemsWheels;
    },
    getCarTag() {
      return this.$route.params.carTag;
    },
    getHsnTsnVsn() {
      return this.$route.params.hsnTsnVsn;
    },
    hasWheelDetailQuery() {
      return this.$route.query.manufacturer && this.$route.query.design && this.$route.query.color;
    },
    carSimulator360Bg() {
      return carSimulator360Bg;
    },
    carSimulatorAngle() {
      if (!this.carImage.angles) {
        return [];
      }
      return this.carImage.angles;
    },
    isEmptySelectedWheel() {
      return _.isEmpty(this.selectedWheel);
    },
    carInfoPdfOption() {
      return {
        margin: 0,

        image: {
          type: 'png',
          quality: 0.98,
        },

        html2canvas: {
          scale: 2,
          useCORS: true,
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      };
    },
  },
  watch: {
    $route(val) {
      if (val.query.wheelDetail) {
        this.showWheelDetail = true;
      } else {
        this.showWheelDetail = false;
      }
    },
    getGroupedCarWheelsByDiameter(val) {
      if (val.length === 0) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.no-wheels-found'),
          message: this.$t('langkey.please-select-a-new-filter'),
        });
        return;
      }
      // if it's wheel overview and wheel results are changed when filter then
      // select the first wheel in the list
      if (!this.$route.query.wheelDetail) {
        const firstWheelGroup = val[0];
        const minimumDiameterFirstWheelGroup = Object.values(firstWheelGroup.possibleDiameters)[0];
        const newSelectedWheel = minimumDiameterFirstWheelGroup[0];
        const pagination = this.$refs.rimconfigWheelOverview.pagination;

        if (
          _.isEmpty(this.selectedWheel)
          || (this.selectedWheel.wheelTag !== newSelectedWheel.wheelTag && pagination === 1)
        ) {
          this.$store.dispatch('rimconfigCarConfigurator/setSelectedWheel', newSelectedWheel)
            .then(() => {
              this.getCarImage();
            });
        }
      }
    },
  },
  async created() {
    if(this.$route.query.wheelDetail) {
      if (!this.hasWheelDetailQuery) {
        this.$router.replace(this.$i18nRoute({
          name: this.$route.name,
          params: this.$route.params,
        }));
      } else {
        this.showWheelDetail = true;
      }
    }

    await this.getCarDetails();

    if (this.getGroupedCarWheelsByDiameter.length > 0) {
      let newSelectedWheel = this.getGroupedCarWheelsByDiameter[0].wheels[0];
      await this.$store.dispatch('rimconfigCarConfigurator/setSelectedWheel', newSelectedWheel);
    }

    if (!_.isEmpty(this.selectedWheel)) {
      this.getCarImage();
    }

    this.selectedBgName = this.carSimulator360Bg[0].key;
    this.selectedBgSrc = this.carSimulator360Bg[0].url;

    this.$store.dispatch('rimconfigCarConfigurator/loadTpmsCar', {
      carTag: this.getCarTag,
    })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.hasTpms = true;
        }
      });

    this.$store.dispatch('rimconfigCarConfigurator/setSelectConfiguratorStep', 1);
  },
  methods: {
    async getCarDetails() {
      if (this.getCarTag) {
        await this.$store.dispatch('rimconfigCarConfigurator/loadCarByCarTag', {
          carTag: this.getCarTag,
        });
      } else if (this.getHsnTsnVsn) {
        const hsn = this.getHsnTsnVsn.split('/')[0];
        const tsnVsn = this.getHsnTsnVsn.split('/')[1];
        await this.$store.dispatch('rimconfigCarConfigurator/getCarGroupByGermanCode', {
          hsn: hsn,
          tsn: tsnVsn.substring(0,3),
          vsn: tsnVsn.substring(3),
        });
      }
    },
    getCarImage() {
      if (this.getCarTag) {
        this.isLoading = true;
        let wheelPayload = {};
        wheelPayload = {
          eans: this.selectedWheel.ean,
          mans: this.selectedWheel.man,
        };

        this.$store.dispatch('rimconfigCarConfigurator/getCarImage', {
          carTag: this.getCarTag,
          ...wheelPayload,
        })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
              this.loadCarBgFrames();
            }, 200);
          });
      }
    },
    toWheelDetail(wheelData) {
      let wheelDetailQuery;
      if (wheelData.length === 1 && wheelData[0].wheels.length === 1) {
        wheelDetailQuery = {
          manufacturer: wheelData.manufacturer,
          design: wheelData.design,
          color: wheelData.color,
          diameter: wheelData[0].wheels[0].diameter,
        };
      } else if (this.isSelectedWheel(wheelData)) {
        wheelDetailQuery = {
          manufacturer: wheelData.manufacturer,
          design: wheelData.design,
          color: wheelData.color,
          diameter: this.selectedWheel.diameter,
        };
      } else {
        wheelDetailQuery = {
          manufacturer: wheelData.manufacturer,
          design: wheelData.design,
          color: wheelData.color,
        };
      }
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag',
        query: {
          wheelDetail: true,
          ...wheelDetailQuery,
        },
      }));
    },
    setCarSelectorStep(step) {
      const promise1 = this.$store.dispatch('rimconfigCarSelector/setStep', step);
      const promise2 = this.$store.dispatch('rimconfigCarSelector/setSearchByKbaNumber', false);
      Promise.all([promise1, promise2])
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-configurator',
          }));
        });
    },
    onShowCarInfo() {
      this.$refs.rimconfigCarInfoRef.show();
    },
    onZoomCar() {
      this.isFullscreen = true;
    },
    onExitZoom() {
      this.isFullscreen = false;
    },
    onSelectWheel(diameterWheels) {
      const newSelectedWheel = diameterWheels[0];
      this.$store.dispatch('rimconfigCarConfigurator/setSelectedWheel', newSelectedWheel)
        .then(() => {
          this.getCarImage();
        });
    },
    onSelectColor(color) {
      this.carColor = color;
    },
    onSelectSuspension(suspension) {
      this.carSuspension = suspension;
    },
    onClickWheels() {
      this.$router.replace(this.$i18nRoute({
        name: this.$route.name,
      }));
    },
    isSelectedWheel(wheelData) {
      const result = wheelData.wheels.some((item) => item.wheelTag === this.selectedWheel.wheelTag);
      return result;
    },
    onSelectBackground(item) {
      if (item === 'blank') {
        this.hideBg = true;
        return;
      }
      this.hideBg = false;
      this.selectedBgName = item.key;
      this.selectedBgSrc = item.url;
      this.loadCarBgFrames();
    },
    loadCarBgFrames() {
      this.isLoading360Bg = true;
      let framePaths = [];
      for (let i = 0; i < this.carSimulatorAngle.length; i++) {
        framePaths.push(require(`../../../../assets/images/carbg/${this.selectedBgName}/angle_${this.carSimulatorAngle[i]}.jpg`));
      }
      return optimisticLoadImg(framePaths)
        .then((result) => {
          this.carBgImages = result;
          setTimeout(() => {
            this.isLoading360Bg = false;
          }, 500);
        })
        .catch(() => {
          this.isLoading360Bg = false;
        });
    },
    onFrameChange(angle) {
      this.carBgFrame = angle;
    },
    onLoadingCarThreesixty(e) {
      this.isLoadingCar360 = e;
    },
    onCarInfoShow(e) {
      this.isCarInfoShow = e;
    },
    onLoadPdfOffer() {
      window.scrollTo(0, 0);
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 600);
    },
    hasDownloaded() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: false,
      });
    },
  },
};
</script>