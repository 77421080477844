export const propertiesFilterFields = [
  {
    key: 'hasAbe',
    label: 'langkey.configurator-prop-abe',
  },
  {
    key: 'hasEce',
    label: 'langkey.configurator-prop-ece',
  },
  {
    key: 'winter',
    label: 'langkey.configurator-prop-winterproof',
  },
];

export const propertiesFilterFieldsMore = [
  {
    key: 'hasSnowChain',
    label: 'langkey.snow-chain',
  },
  {
    key: 'alloy',
    label: 'langkey.wheel-alloy',
  },
  {
    key: 'steel',
    label: 'langkey.wheel-steel',
  },
  {
    key: 'stockMoreThanFour',
    label: 'langkey.stockmorethanfour',
  },
];

export const selectFilterFields = [
  {
    key: 'wheelManufacturers',
    filterKey: 'wheelManufacturers',
    placeholder: 'langkey.configurator-prop-manufacturer',
    singleSelectLabel: 'Manufacturer',
    pluralSelectLabel: 'Manufacturers',
    sortable: true,
  },
  {
    key: 'wheelDesigns',
    filterKey: 'wheelDesigns',
    placeholder: 'langkey.configurator-prop-design',
    singleSelectLabel: 'Design',
    pluralSelectLabel: 'Designs',
  },
  {
    key: 'diameters',
    filterKey: 'diameters',
    placeholder: 'langkey.configurator-prop-diameter',
    singleSelectLabel: 'Diameter',
    pluralSelectLabel: 'Diameters',
    sortable: true,
  },
  {
    key: 'widths',
    filterKey: 'widths',
    placeholder: 'langkey.configurator-prop-width',
    singleSelectLabel: 'Width',
    pluralSelectLabel: 'Widths',
    sortable: true,
  },
  {
    key: 'colors',
    filterKey: 'colors',
    placeholder: 'langkey.configurator-prop-color',
    singleSelectLabel: 'Color',
    pluralSelectLabel: 'Colors',
  },
];

export const selectFilterFieldsMore = [
  {
    key: 'boltPatterns',
    filterKey: 'boltPatterns',
    placeholder: 'langkey.configurator-prop-bolt-circle',
    singleSelectLabel: 'Bolt circle',
    pluralSelectLabel: 'Bolt circles',
  },
  {
    key: 'offsets',
    filterKey: 'offsets',
    placeholder: 'langkey.configurator-prop-offset',
    singleSelectLabel: 'Offset',
    pluralSelectLabel: 'Offsets',
  },
  {
    key: 'centerbores',
    filterKey: 'centerbores',
    placeholder: 'langkey.configurator-prop-centerbore',
    singleSelectLabel: 'Centerbore',
    pluralSelectLabel: 'Centerbores',
  },
  // {
  //   key: 'surface',
  //   filterKey: 'centerbores',
  //   placeholder: 'langkey.configurator-prop-surface',
  //   singleSelectLabel: 'Surface',
  //   pluralSelectLabel: 'Surfaces',
  // },
  // {
  //   key: 'constructionType',
  //   placeholder: 'langkey.configurator-prop-contruct',
  //   singleSelectLabel: 'Construction',
  //   pluralSelectLabel: 'Constructions',
  // },
];

export const propertiesFilterFieldsWheelDetail = [
  {
    key: 'hasAbe',
    label: 'langkey.configurator-prop-abe',
  },
  {
    key: 'hasEce',
    label: 'langkey.configurator-prop-ece',
  },
  {
    key: 'winter',
    label: 'langkey.configurator-prop-winterproof',
  },
];

export const selectFilterFieldsWheelDetail = [
  {
    key: 'widths',
    filterKey: 'widths',
    placeholder: 'langkey.configurator-prop-width',
    singleSelectLabel: 'Width',
    pluralSelectLabel: 'Widths',
    sortable: true,
  },
  {
    key: 'diameters',
    filterKey: 'diameters',
    placeholder: 'langkey.configurator-prop-diameter',
    singleSelectLabel: 'Diameter',
    pluralSelectLabel: 'Diameters',
    sortable: true,
  },
  {
    key: 'offsets',
    filterKey: 'offsets',
    placeholder: 'langkey.configurator-prop-offset',
    singleSelectLabel: 'ET',
    pluralSelectLabel: 'ETs',
    sortable: true,
  },
];

export const wheelDetailTableFields = [
  { text: 'langkey.axle', align: 'center', value: 'axisOnly', sortable: false },
  { text: 'langkey.inch', align: 'center', value: 'width' },
  { text: 'langkey.offset', align: 'center', value: 'offset' },
  // { text: 'Stock', align: 'center', value: 'stock', sortable: false },
  { text: 'ECE', align: 'center', value: 'hasEce', sortable: false },
  { text: 'ABE', align: 'center', value: 'hasAbe', sortable: false },
  { text: 'langkey.installation-information', align: 'center', value: 'installationInfo', sortable: false },
  { text: 'langkey.certificate', align: 'center', value: 'certificate', sortable: false },
  { text: 'langkey.best-price-rim-config', align: 'center', value: 'price' },
  // { text: 'langkey.quantity', align: 'center', value: 'quantity', sortable: false },
  { text: '', align: 'center', value: 'cart', sortable: false },
];

export const wheelDetailTableFieldsRetail = [
  { text: 'langkey.axle', align: 'center', value: 'axisOnly', sortable: false },
  { text: 'langkey.inch', align: 'center', value: 'width' },
  { text: 'langkey.offset', align: 'center', value: 'offset' },
  // { text: 'Stock', align: 'center', value: 'stock', sortable: false },
  { text: 'ECE', align: 'center', value: 'hasEce', sortable: false },
  { text: 'ABE', align: 'center', value: 'hasAbe', sortable: false },
  { text: 'langkey.installation-information', align: 'center', value: 'installationInfo', sortable: false },
  { text: 'langkey.certificate', align: 'center', value: 'certificate', sortable: false },
  { text: 'langkey.netto', align: 'center', value: 'retailPrice' },
  { text: 'langkey.brutto', align: 'center', value: 'retailTaxPrice' },
  // { text: 'langkey.quantity', align: 'center', value: 'quantity', sortable: false },
  { text: '', align: 'center', value: 'cart', sortable: false },
];

export const carCocInfoTableFields = [
  { text: 'langkey.front-tyre', align: 'center', value: 'frontTyre' },
  { text: 'langkey.front-wheel', align: 'center', value: 'frontWheel' },
  { text: 'langkey.rear-tyre', align: 'center', value: 'rearTyre' },
  { text: 'langkey.rear-wheel', align: 'center', value: 'rearWheel' },
];

export const breadcrumbItemsWheels = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'model',
    label: 'langkey.breadcrumb-model',
    isCarSelector: true,
    step: 2,
  },
  {
    key: 'type',
    label: 'langkey.breadcrumb-type',
    isCarSelector: true,
    step: 3,
  },
  {
    key: 'version',
    label: 'langkey.breadcrumb-version',
    isCarSelector: true,
    step: 4,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
  },
];

export const breadcrumbItemsWheelsNoState = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
  },
];


export const breadcrumbItemsWheelsDetails = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'model',
    label: 'langkey.breadcrumb-model',
    isCarSelector: true,
    step: 2,
  },
  {
    key: 'type',
    label: 'langkey.breadcrumb-type',
    isCarSelector: true,
    step: 3,
  },
  {
    key: 'version',
    label: 'langkey.breadcrumb-version',
    isCarSelector: true,
    step: 4,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
  },
  {
    key: 'wheelsDetails',
    label: 'langkey.breadcrumb-wheels-details',
  },
];

export const breadcrumbItemsWheelsDetailsNoState = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
  },
  {
    key: 'wheelsDetails',
    label: 'langkey.breadcrumb-wheels-details',
  },
];

export const carSimulator360Bg = [
  {
    key: 'industrial',
    url: require('@/assets/images/carbg/industrial/angle_0.jpg'),
    iconColor: 'light',
  },
  {
    key: 'cannon',
    url: require('@/assets/images/carbg/cannon/angle_0.jpg'),
    iconColor: 'dark',
  },
  {
    key: 'playazo',
    url: require('@/assets/images/carbg/playazo/angle_0.jpg'),
    iconColor: 'dark',
  },
  {
    key: 'fiume',
    url: require('@/assets/images/carbg/fiume/angle_0.jpg'),
    iconColor: 'dark',
  },
];

export const tableFieldSelectedWheel = [
  { value: 'image', text: 'langkey.image', width: '200px', align: 'center' },
  { value: 'description', text: 'langkey.article', width: '300px', align: 'center' },
  { value: 'articleId', text: 'langkey.article-id', width: '135px', align: 'center' },
  { value: 'quantity', text: 'langkey.quantity', width: '180px', align: 'center' },
  { value: 'total', text: 'langkey.total-price', width: '110px', align: 'center' },
  { value: 'data-table-expand' },
];
