<template>
  <div class="rimconfig-wheel-detail">
    <base-rimconfig-filter-bar
      :is-show-more="isShowAllFilter"
      is-car-configurator
      @reset="onResetFilter"
      @show-all="onShowAllFilter"
    >
      <template #select-group>
        <div class="initial-filter-list">
          <div class="configurator-car-select-filter-list wheel-detail">
            <base-select-filter-dropdown
              v-for="(selectField, index) in selectFilterFieldsWheelDetail"
              :key="`select${selectField.key}${index}`"
              v-model="inputSelectFilter[selectField.key]"
              :is-loading="isLoadingFilter"
              :placeholder="$t(selectField.placeholder)"
              :select-label="inputSelectFilter[selectField.key].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
              :option-items="selectFilterOptions[selectField.key] || []"
              :sortable="selectField.sortable"
              @input="onInputSelectFilter"
            />
          </div>
        </div>
        <div class="configurator-car-checkbox-list wheel-detail">
          <base-checkbox
            v-for="(property, index) in propertiesFilterFieldsWheelDetail"
            :key="`${property.key}${index}`"
            v-model="inputPropertyFilter[property.key]"
            :label-text="$t(property.label)"
            class="checkbox-item"
            reverse
            @input="onInputSelectFilter"
          />
        </div>
      </template>
    </base-rimconfig-filter-bar>

    <section class="wheel-detail-table-section">
      <v-container>
        <v-data-table
          class="primary-style wheel-detail-table"
          :headers="wheelDetailTableFields"
          :items="wheelDetailTableItemsCombination"
          :items-per-page="-1"
          :loading="isLoadingWheelDetail"
          hide-default-footer
          item-key="articleId"
        >
          <template
            v-for="(field, index) in wheelDetailTableFields"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <div
              :key="`${field.value}${index}`"
              class="d-flex align-center justify-center"
            >
              <span>{{ $t(header.text) }}</span>
              <div class="icon__sort" />
            </div>
          </template>
          <template v-slot:[`item.axisOnly`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ getAxis(item.F.axisOnly) }}<br>
              {{ getAxis(item.R.axisOnly) }}
            </div>
            <span v-else>{{ getAxis(item.axisOnly) }}</span>
          </template>
          <template v-slot:[`item.width`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ item.F.width | wheelWidth }} x {{ item.F.diameter }}<br>
              {{ item.R.width | wheelWidth }} x {{ item.R.diameter }}
            </div>
            <span v-else>{{ item.width | wheelWidth }} x {{ item.diameter }}</span>
          </template>
          <template v-slot:[`item.offset`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ item.F.offset }}<br>
              {{ item.R.offset }}
            </div>
            <span v-else>{{ item.offset }}</span>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ item.F.stock }}<br>
              {{ item.R.stock }}
            </div>
            <span v-else>{{ item.stock }}</span>
          </template>
          <template v-slot:[`item.hasEce`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination icons__wrapper"
            >
              <icon-availability :available="item.F.hasEce" />
              <icon-availability :available="item.R.hasEce" />
            </div>
            <div
              v-else
              class="icon__wrapper"
            >
              <icon-availability :available="item.hasEce" />
            </div>
          </template>
          <template v-slot:[`item.hasAbe`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination icons__wrapper"
            >
              <icon-availability :available="item.F.hasAbe" />
              <icon-availability :available="item.R.hasAbe" />
            </div>
            <div
              v-else
              class="icon__wrapper"
            >
              <icon-availability :available="item.hasAbe" />
            </div>
          </template>
          <template v-slot:[`item.installationInfo`]="{ item }">
            <v-btn
              icon
              plain
              @click="onClickInstallationInformation(item)"
            >
              <icon-screwdriver />
            </v-btn>
          </template>
          <template v-slot:[`item.certificate`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination icons__wrapper"
            >
              <a
                :href="item.F.certificateUrl"
                target="_blank"
                class="d-flex align-center justify-center"
              >
                <icon-certificate />
              </a>
              <a
                :href="item.R.certificateUrl"
                target="_blank"
                class="d-flex align-center justify-center"
              >
                <icon-certificate />
              </a>
            </div>
            <!-- <a
              :href="value"
              target="_blank"
            > -->
            <a
              v-else
              :href="item.certificateUrl"
              target="_blank"
              class="d-flex align-center justify-center"
            >
              <div
                class="icon__wrapper"
              >
                <icon-certificate />
              </div>
            </a>
            <!-- </a> -->
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ (item.F.prices.bestPrice.purchasePrice || 0) | price }}<br>
              {{ (item.R.prices.bestPrice.purchasePrice || 0) | price }}
            </div>
            <span v-else>{{ (item.prices.bestPrice.purchasePrice || 0) | price }}</span>
          </template>
          <template v-slot:[`item.retailPrice`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ (item.F.prices.bestPrice.retailPrice || 0) | price }}<br>
              {{ (item.R.prices.bestPrice.retailPrice || 0) | price }}
            </div>
            <span v-else>{{ (item.prices.bestPrice.retailPrice || 0) | price }}</span>
          </template>
          <template v-slot:[`item.retailTaxPrice`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              {{ (item.F.prices.bestPrice.retailTaxPrice || 0) | price }}<br>
              {{ (item.R.prices.bestPrice.retailTaxPrice || 0) | price }}
            </div>
            <span v-else>{{ (item.prices.bestPrice.retailTaxPrice || 0) | price }}</span>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <template
              v-if="item.isCombination"
              class="combination"
            >
              <div
                v-for="(wheel, wheelIndex) in [item.F, item.R]"
                :key="wheelIndex"
                class="qty__wrapper"
              >
                <v-btn
                  class="qty-minus"
                  icon
                  plain
                  @click="onSubtractQuantity(`${wheel.man}${wheel.axisOnly}${wheel.combination}`)"
                >
                  <icon-subtract />
                </v-btn>
                <input
                  v-model.number="selectQuantity[`${wheel.man}${wheel.axisOnly}${wheel.combination}`]"
                  class="qty__input"
                  @change="onUpdateQuantity($event, `${wheel.man}${wheel.axisOnly}${wheel.combination}`)"
                >
                <v-btn
                  class="qty-plus"
                  icon
                  plain
                  @click="onAddQuantity(`${wheel.man}${wheel.axisOnly}${wheel.combination}`)"
                >
                  <icon-add />
                </v-btn>
              </div>
            </template>
            <div
              v-else
              class="qty__wrapper"
            >
              <v-btn
                class="qty-minus"
                icon
                plain
                @click="onSubtractQuantity(`${item.man}${item.axisOnly}${item.combination}`)"
              >
                <icon-subtract />
              </v-btn>
              <input
                v-model.number="selectQuantity[`${item.man}${item.axisOnly}${item.combination}`]"
                class="qty__input"
                @change="onUpdateQuantity($event, `${item.man}${item.axisOnly}${item.combination}`)"
              >
              <v-btn
                class="qty-plus"
                icon
                plain
                @click="onAddQuantity(`${item.man}${item.axisOnly}${item.combination}`)"
              >
                <icon-add />
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.cart`]="{ item }">
            <v-btn
              icon
              plain
              @click="onAddToCart(item)"
            >
              <icon-add-to-cart />
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </section>

    <base-add-to-cart-dialog
      ref="addToCartDialog"
      is-rim-config
      is-wheel-configurator
      :cart-items="cartItems"
      :is-combination-wheel="isCombinationWheel"
      :select-quantity-table="selectQuantity"
      supplier-source
      @on-subtract-quantity="onSubtractQuantityCart"
      @on-add-quantity="onAddQuantityCart"
      @on-update-quantity="onUpdateQuantityCart"
      @confirm-and-show-cart="onConfirmAndShowCart"
      @rimconfig-next="onCartNext"
      @supplier-add-cart="onSupplierAddToCart"
    />

    <v-dialog
      v-model="showWithTpmsDialog"
      max-width="550"
    >
      <v-card class="dialog rimconfig-wheel-detail">
        <v-card-title>
          <icon-tpms
            class="mr-3"
            color="var(--theme-black)"
          />
          <h5 class="text">
            {{ $t('langkey.wheel-detail-continue-tpms-head') }}
          </h5>
        </v-card-title>
        <v-card-text>
          {{ $t('langkey.wheel-detail-continue-tpms-body') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="icon"
            @click="goToRimconfigTpms"
          >
            {{ $t('langkey.wheel-detail-continue-tpms-yes') }}
          </v-btn>
          <v-btn
            outlined
            plain
            class="icon"
            @click="goToRimconfigTyre"
          >
            {{ $t('langkey.wheel-detail-continue-tpms-no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showContinueShoppingOrShowCartDialog"
      max-width="1000"
    >
      <v-card class="dialog wheel-detail">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.cart-dialog-head') }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="primary-style select-wheel-table"
            :headers="tableFieldSelectedWheel"
            :items="cartItems"
            hide-default-footer
            disable-sort
          >
            <template
              v-for="(field, index) in tableFieldSelectedWheel"
              v-slot:[`header.${field.value}`]="{ header }"
            >
              <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <img
                v-show="item.imageTemplate"
                v-lazy="getTpmsImageBySize(item.imageTemplate)"
                class="cart-img"
              >
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <div
                v-if="item.isCombination"
                class="combination"
              >
                <div>{{ item.F.description }}</div>
                <div class="mt-3">
                  {{ item.R.description }}
                </div>
              </div>
              <span v-else>{{ item.description }}</span>
            </template>
            <template v-slot:[`item.articleId`]="{ item }">
              <div
                v-if="item.isCombination"
                class="combination"
              >
                <div>{{ item.F.articleId }}</div>
                <div class="mt-3">
                  {{ item.R.articleId }}
                </div>
              </div>
              <span v-else>{{ item.articleId }}</span>
            </template>
            <template v-slot:[`item.quantity`]="{ item, index }">
              <div class="qty__wrapper">
                <v-btn
                  :disabled="selectedWheels.quantity < 2"
                  icon
                  plain
                  @click="onSubtractQuantitySelectedItem"
                >
                  <icon-subtract />
                </v-btn>
                <input
                  v-model.number="selectedWheels.quantity"
                  class="qty__input"
                  @change="onUpdateQuantitySelectedItem($event, index)"
                >
                <v-btn
                  :disabled="selectedWheels.quantity >= item.stock"
                  icon
                  plain
                  @click="onAddQuantitySelectedItem"
                >
                  <icon-add />
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.bestPrice[priceType] * selectedWheels.quantity | price }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <div
            v-if="hasTpms"
            class="d-flex flex-column align-center"
          >
            <p class="fitted-wheel-text">
              {{ $t('langkey.proceed-with-fitted-wheel') }}
            </p>
            <v-btn
              class="icon"
              color="primary"
              @click="onContinueTpmsDialog"
            >
              {{ $t('langkey.continue-with-tpms') }} <icon-arrow color="var(--theme-white)" />
            </v-btn>
          </div>
          <div
            v-else
            class="d-flex flex-column align-center"
          >
            <p class="fitted-wheel-text">
              {{ $t('langkey.proceed-with-fitted-wheel') }}
            </p>
            <v-btn
              class="icon"
              color="primary"
              @click="continueToTyre"
            >
              {{ $t('langkey.continue-with-tyres') }} <icon-arrow color="var(--theme-white)" />
            </v-btn>
          </div>
          <v-btn
            class="icon show-cart"
            outlined
            plain
            @click="onShowCart"
          >
            {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show color="var(--theme-black)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-dialog
      ref="continueToTpmsDialog"
      dialog-class="continue-to-tpms-dialog"
      :title="$t('langkey.continue-with-tpms')"
      :max-width="550"
    >
      <v-card-actions>
        <v-btn
          class="icon"
          color="primary"
          @click="continueToTpms"
        >
          {{ $t('langkey.continue-with-tpms-text') }}
          <icon-availability
            :available="true"
            color="var(--theme-white)"
          />
        </v-btn>
        <v-btn
          outlined
          plain
          class="icon"
          @click="continueToTyre"
        >
          {{ $t('langkey.skip-to-tyres') }} <icon-arrow color="var(--theme-black)" />
        </v-btn>
      </v-card-actions>
    </base-dialog>

    <rimconfig-installation-info-dialog
      ref="rimconfigInstallationInfoRef"
      :title="installationInfoPopupTitle"
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-detail.scss";
</style>

<script>
import BaseRimconfigFilterBar from '@/components/BaseRimconfigFilterBar';
import IconTpms from '@/components/icons/IconTpms';
import IconAvailability from '@/components/icons/IconAvailability';
import IconCertificate from '@/components/icons/IconCertificate';
import IconSubtract from '@/components/icons/IconSubtract';
import IconAdd from '@/components/icons/IconAdd';
import IconCartShow from '@/components/icons/IconCartShow';
import IconScrewdriver from '@/components/icons/IconScrewdriver';
import IconArrow from '@/components/icons/IconArrow';
import RimconfigInstallationInfoDialog from '@/components/RimconfigInstallationInfoDialog';
import {
  selectFilterFieldsWheelDetail,
  propertiesFilterFieldsWheelDetail,
  wheelDetailTableFields,
  wheelDetailTableFieldsRetail,
  tableFieldSelectedWheel,
} from '@/pages/rims-config/configurator/_carTag/configs';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { getImageBySize } from '@/utils/index.js';

export default {
  components: {
    BaseRimconfigFilterBar,
    IconTpms,
    IconAvailability,
    IconCertificate,
    IconSubtract,
    IconAdd,
    IconCartShow,
    IconScrewdriver,
    IconArrow,
    RimconfigInstallationInfoDialog,
  },
  props: {
    hasTpms: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowAllFilter: false,
      isLoadingWheelDetail: false,
      isLoadingFilter: false,

      inputSelectFilter: {},
      inputPropertyFilter: {},

      showWithTpmsDialog: false,
      showContinueShoppingOrShowCartDialog: false,

      wheelDetailTableItems: [],

      selectQuantity: {},
      cartItems: [],
      supplierCartItems: {},
      selectedWheels: {},
      isCombinationWheel: false,
      installationInfoPopupTitle: '',
    };
  },
  computed: {
    ...mapState('rimconfigCarConfigurator',
      [
        'carWheelAssignByWheelTag',
        'carData',
        'carWheelsSelectedFilters',
      ],
    ),
    ...mapState('pricing', ['priceType']),
    ...mapGetters('rimconfigCarConfigurator',
      [
        'getWheelDetailFilterOption',
        'getGroupedCarWheelsByDiameter',
        'getCarWheelsSelectFilterOptions',
      ],
    ),
    selectFilterOptions() {
      let selectFilterFieldsAll = [
        ...this.selectFilterFieldsWheelDetail,
      ];
      return this.getCarWheelsSelectFilterOptions(selectFilterFieldsAll);
    },
    getCarInfoTitle() {
      if (!_.isEmpty(this.carData)) {
        return `${this.carData.manufacturer} ${this.carData.class} ${this.carData.version} ${this.carData.power}KW/${this.carData.systemPower}PS`;
      } else {
        return '';
      }
    },
    getCarInfoSubtitle() {
      if (!_.isEmpty(this.carData)) {
        return `${this.carData.typeApproval} ${this.carData.hsn} / ${this.carData.tsn} / ${this.carData.vsn}`;
      } else {
        return '';
      }
    },
    propertiesFilterFieldsWheelDetail() {
      return propertiesFilterFieldsWheelDetail;
    },
    selectFilterFieldsWheelDetail() {
      return selectFilterFieldsWheelDetail;
    },
    wheelDetailTableFields() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return wheelDetailTableFieldsRetail;
      } else {
        return wheelDetailTableFields;
      }
    },
    tableFieldSelectedWheel() {
      return tableFieldSelectedWheel;
    },
    wheelDetailTableItemsCombination() {
      if (this.wheelDetailTableItems.length === 0) {
        return [];
      }
      const groupArr = [
        ...this.wheelDetailTableItems.reduce((reducer, obj) => {
          const key = obj.combination;
          const item = reducer.get(key)
            ? {
              ...reducer.get(key),
              [obj.axisOnly]: obj,
              children: [
                ...reducer.get(key).children,
                obj,
              ],
            } :
            {
              ...obj,
              [obj.axisOnly]: obj,
              children: [
                obj,
              ],
            };
          return reducer.set(key, item);
        }, new Map())
          .values(),
      ].map((group) => {
        if (group['F'] && group['R']) {
          return {
            ...group,
            ...group.F,
            isCombination: true,
          };
        } else {
          return group;
        }
      });

      let result = [];
      groupArr.forEach((group) => {
        if (group.axisOnly === 'B') {
          result.push(...group.children);
        } else {
          result.push(group);
        }
      });
      return result.sort((a,b) => a.width - b.width || a.diameter - b.diameter);
    },
    getCarTag() {
      return this.$route.params.carTag;
    },
    initWheelDetailFilterPayload() {
      const { manufacturer, design, color, diameter } = this.$route.query;
      let filterPayload = {
        carTag: this.getCarTag,
        wheelManufacturers: manufacturer,
        wheelDesigns: design,
        colors: color,
      };
      if (diameter) {
        filterPayload = {
          ...filterPayload,
          diameters: diameter,
        };
      }
      return filterPayload;
    },
  },
  watch: {
    wheelDetailTableItemsCombination(val) {
      if (val.length !== 0) {
        this.setInitSelectQuantity();
      }
    },
    getGroupedCarWheelsByDiameter(val) {
      // Fix for the moment. Get only wheels with chosen color
      if (val.length === 0) {
        this.wheelDetailTableItems = [];
        return;
      }
      const diameter = this.$route.query.diameter;
      this.wheelDetailTableItems = val[0].wheels;
      let newSelectedWheel;
      if (diameter) {
        newSelectedWheel = this.wheelDetailTableItems
          .find((item) => item.diameter === parseInt(diameter));
      } else {
        newSelectedWheel = this.wheelDetailTableItems[0];
      }
      this.$store.dispatch('rimconfigCarConfigurator/setSelectedWheel', newSelectedWheel)
        .then(() => {
          this.$emit('get-car-image');
        });
    },
  },
  created() {
    this.isLoadingFilter = true;
    this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', this.initWheelDetailFilterPayload)
      .finally(() => {
        this.isLoadingFilter = false;
      });
    this.getWheelDetailData();
    // set input from selected state in wheel overview
    if (this.carWheelsSelectedFilters.inputSelectFilter) {
      this.setInitInputSelectFilterFromSelectedState();
      this.setInitInputPropertyFilterFromSelectedState();
    } else {
      // set init value
      this.setInitInputSelectFilter();
      this.setInitInputPropertyFilter();
    }

    this.setInitSelectQuantity();
  },
  beforeDestroy() {
    this.setInitInputPropertyFilter();
    this.setInitInputSelectFilter();
    this.$store.dispatch('rimconfigCarConfigurator/setSeletedFilters', {
      inputSelectFilter: this.inputSelectFilter,
      inputPropertyFilter: this.inputPropertyFilter,
    });
  },
  methods: {
    async getWheelDetailData() {
      this.isLoadingWheelDetail = true;
      await this.$store.dispatch('rimconfigCarConfigurator/getCarWheels', this.initWheelDetailFilterPayload)
        .finally(() => {
          this.isLoadingWheelDetail = false;
        });
    },
    getWheelDetailFilter(filterData) {
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheels', {
        ...this.initWheelDetailFilterPayload,
        ...filterData,
      })
        .then(() => {
          setTimeout(() => {
            this.isLoadingWheelDetail = false;
          }, 300);
        })
        .catch(() => {
          this.isLoadingWheelDetail = false;
        });
    },
    onInputSelectFilter() {
      const selectFilterPayload = this.getFilterPayload(this.inputSelectFilter);
      let propertyFilterPayload = {};
      Object.keys(this.inputPropertyFilter).forEach((propertyKey) => {
        if (this.inputPropertyFilter[propertyKey]) {
          propertyFilterPayload = {
            ...propertyFilterPayload,
            [propertyKey]: true,
          };
        }
      });
      this.$store.dispatch('rimconfigCarConfigurator/setSeletedFilters', {
        inputSelectFilter: this.inputSelectFilter,
        inputPropertyFilter: this.inputPropertyFilter,
      });
      this.isLoadingFilter = true;
      this.isLoadingWheelDetail = true;
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', {
        ...this.initWheelDetailFilterPayload,
        ...selectFilterPayload,
        ...propertyFilterPayload,
        carTag: this.getCarTag,
      })
        .then(() => {
          this.getWheelDetailFilter({
            ...selectFilterPayload,
            ...propertyFilterPayload,
            carTag: this.getCarTag,
          });
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error getCarWheelsConcatenated',
            message: err,
          });
        })
        .finally(() => {
          this.isLoadingFilter = false;
        });
    },
    getFilterPayload(filters) {
      let payload = {};
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          payload = {
            ...payload,
            [key]: filters[key].join(),
          };
        } else if (!Array.isArray(filters[key]) && filters[key]) {
          payload = {
            ...payload,
            [key]: filters[key],
          };
        }
      });
      return payload;
    },
    async onAddToCart(item) {
      this.isCombinationWheel = false;
      this.cartItems = [];
      if (!item.isCombination) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        this.$store.dispatch('rimconfigCarConfigurator/getCarWheelAssigns', {
          wheelTag: item.wheelTag,
          assigns: item.assigns,
        }).then(() => {
          const wheelAssigns = this.carWheelAssignByWheelTag[item.wheelTag].slice()
            .map((assignItem) => {
              return {
                ...assignItem,
              };
            });
          this.cartItems.push({
            ...item,
            imageTemplate: item.picturePerspective,
            description: item.description,
            articleId: item.man,
            quantity: this.selectQuantity[`${item.man}${item.ean}`] || 1,
            price: item.price || 0,
            suppliers: wheelAssigns,
          });
          this.$refs.addToCartDialog.show();
        }).finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
        return;
      }

      this.isCombinationWheel = true;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('rimconfigCarConfigurator/getCarWheelAssigns', {
          wheelTag: item.F.wheelTag,
          assigns: item.F.assigns,
        });
        await this.$store.dispatch('rimconfigCarConfigurator/getCarWheelAssigns', {
          wheelTag: item.R.wheelTag,
          assigns: item.R.assigns,
        });
        const wheelAssignsF = this.carWheelAssignByWheelTag[item.F.wheelTag].slice()
          .map((assignItem) => {
            return {
              ...assignItem,
            };
          });
        this.cartItems.push({
          ...item.F,
          imageTemplate: item.F.picturePerspective,
          description: item.F.description,
          articleId: item.F.man,
          quantity: this.selectQuantity[`${item.F.man}${item.F.ean}`] || 1,
          price: item.F.price || 0,
          suppliers: wheelAssignsF,
        });

        const wheelAssignsR = this.carWheelAssignByWheelTag[item.R.wheelTag].slice()
          .map((assignItem) => {
            return {
              ...assignItem,
            };
          });
        this.cartItems.push({
          ...item.R,
          imageTemplate: item.R.picturePerspective,
          description: item.R.description,
          articleId: item.R.man,
          quantity: this.selectQuantity[`${item.R.man}${item.R.ean}`] || 1,
          price: item.R.price || 0,
          suppliers: wheelAssignsR,
        });

        setTimeout(() => {
          this.$refs.addToCartDialog.show();
        }, 200);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    setInitInputSelectFilter() {
      selectFilterFieldsWheelDetail.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.key]: [],
        };
      });
    },
    setInitInputSelectFilterFromSelectedState() {
      selectFilterFieldsWheelDetail.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.key]: this.carWheelsSelectedFilters.inputSelectFilter[item.key] || [],
        };
      });
    },
    setInitInputPropertyFilter() {
      propertiesFilterFieldsWheelDetail.forEach((item) => {
        this.inputPropertyFilter = {
          ...this.inputPropertyFilter,
          [item.key]: false,
        };
      });
    },
    setInitInputPropertyFilterFromSelectedState() {
      propertiesFilterFieldsWheelDetail.forEach((item) => {
        this.inputPropertyFilter = {
          ...this.inputPropertyFilter,
          [item.key]: this.carWheelsSelectedFilters.inputPropertyFilter[item.key]
            ? true
            : false,
        };
      });
    },
    getAxis(axisOnly) {
      if (axisOnly === 'B') {
        return 'VA+HA';
      } else if (axisOnly === 'F') {
        return 'VA';
      } else {
        return 'HA';
      }
    },
    setInitSelectQuantity() {
      this.wheelDetailTableItemsCombination.forEach((item) => {
        if (!item.isCombination) {
          this.selectQuantity = {
            ...this.selectQuantity,
            [`${item.man}${item.axisOnly}${item.combination}`]: 4,
          };
        } else {
          item.children.forEach((wheel) => {
            this.selectQuantity = {
              ...this.selectQuantity,
              [`${wheel.man}${wheel.axisOnly}${wheel.combination}`]: 2,
            };
          });
        }
      });
    },
    onConfirmAndShowCart() {
      this.$refs.addToCartDialog.hide();
    },
    onSupplierAddToCart({ item, quantity, cartItems }) {
      this.$refs.addToCartDialog.hide();
      this.showContinueShoppingOrShowCartDialog = true;
      this.supplierCartItems = {
        item: item,
        quantity: quantity,
        cartItems: cartItems,
      };
      for (let index = 0; index < cartItems.length; index++) {
        this.cartItems[index].stock = item.stock;
        this.cartItems[index].assignId = item.id;
        this.cartItems[index].bestPrice = cartItems[index].prices.bestPrice;
      }
      this.selectedWheels = {
        assignIds: cartItems.length > 1 ? [cartItems[0].assignId,cartItems[1].assignId] : [cartItems[0].assignId],
        isCombination: cartItems.length > 1 ? true : false,
        quantity: quantity,
        cartItems: [],
      };
      cartItems.forEach((item) => {
        const wheelItem = { ...item };
        this.selectedWheels = {
          ...this.selectedWheels,
          cartItems: [
            ...this.selectedWheels.cartItems,
            wheelItem,
          ],
        };
      });
    },
    async onContinueShopping() {
      this.showContinueShoppingOrShowCartDialog = false;
      const userType = sessionStorage.getItem('userType');
      if (userType && userType.toString().toLowerCase().includes('test')) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      const { item, quantity, cartItems } = this.supplierCartItems;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('cart/addCartItem', {
          id: cartItems[0].assignId,
          quantity: this.selectedWheels.quantity,
          context: {
            kitMan: cartItems[0].kitMan || 'Serie',
            carId: this.carData.tag,
            // carDescription: `${this.getCarInfoTitle} ${this.getCarInfoSubtitle}`,
          },
        });
        if (this.isCombinationWheel) {
          await this.$store.dispatch('cart/addCartItem', {
            id: cartItems[1].assignId,
            quantity: quantity,
            context: {
              kitMan: cartItems[1].kitMan || 'Serie',
              carId: this.carData.tag,
              // carDescription: `${this.getCarInfoTitle} ${this.getCarInfoSubtitle}`,
            },
          });
        }
      } catch (err) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error Add item to cart',
          message: err,
        });
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    async onShowCart() {
      this.showContinueShoppingOrShowCartDialog = false;
      const userType = sessionStorage.getItem('userType');
      if (userType && userType.toString().toLowerCase().includes('test')) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      const { item, quantity, cartItems } = this.supplierCartItems;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('cart/addCartItem', {
          id: cartItems[0].assignId,
          quantity: this.selectedWheels.quantity,
          context: {
            kitMan: cartItems[0].kitMan || 'Serie',
            carId: this.carData.tag,
            // carDescription: `${this.getCarInfoTitle} ${this.getCarInfoSubtitle}`,
          },
        });
        if (this.isCombinationWheel) {
          await this.$store.dispatch('cart/addCartItem', {
            id: cartItems[1].assignId,
            quantity: this.selectedWheels.quantity,
            context: {
              kitMan: cartItems[1].kitMan || 'Serie',
              carId: this.carData.tag,
              // carDescription: `${this.getCarInfoTitle} ${this.getCarInfoSubtitle}`,
            },
          });
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'cart',
        }));
      } catch (err) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error Add item to cart',
          message: err,
        });
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    async onCartNext() {
      // save selected data
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      let wheelArticleNumber;
      if (this.cartItems[0].isCombination) {
        const frontWheel = this.cartItems[0].F;
        const rearWheel = this.cartItems[0].R;
        wheelArticleNumber = `${frontWheel.man},${rearWheel.man}`;
      } else {
        const wheel = this.cartItems[0];
        wheelArticleNumber = wheel.articleId;
      }
      await this.$store.dispatch('rimconfigCarConfigurator/loadTpmsCar', {
        carTag: this.getCarTag,
        wheelArticleNumber: wheelArticleNumber,
      })
        .then((res) => {
          if (res.data.length !== 0) {
            this.showWithTpmsDialog = true;
          } else {
            this.goToRimconfigTyre();
          }
        })
        .catch((err) => {
          this.goToRimconfigTyre();
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    goToRimconfigTpms() {
      let selectedWheelArticleNumber;
      if (this.cartItems[0].isCombination) {
        const frontWheel = this.cartItems[0].F;
        const rearWheel = this.cartItems[0].R;
        selectedWheelArticleNumber = `${frontWheel.man}_${frontWheel.quantity}+${rearWheel.man}_${rearWheel.quantity}`;
      } else {
        const wheel = this.cartItems[0];
        selectedWheelArticleNumber = `${wheel.articleId}_${wheel.quantity}`;
      }
      const wheelDetailParams = Object.values(this.$route.query)
        .filter((item) => item !== true)
        .join('+');
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag-tpms',
        params: {
          selectedWheel: `${wheelDetailParams}__${selectedWheelArticleNumber}`,
        },
      }));
    },
    goToRimconfigTyre() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag-tyre',
      }));
    },
    onShowAllFilter() {
      this.isShowAllFilter = !this.isShowAllFilter;
    },
    onResetFilter() {
      this.setInitInputPropertyFilter();
      this.setInitInputSelectFilter();
      this.$store.dispatch('rimconfigCarConfigurator/setSeletedFilters', {
        inputSelectFilter: this.inputSelectFilter,
        inputPropertyFilter: this.inputPropertyFilter,
      });
      this.isLoadingFilter = true;
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', this.initWheelDetailFilterPayload)
        .then(() => {
          this.getWheelDetailData();
        })
        .finally(() => {
          this.isLoadingFilter = false;
        });
    },
    onSubtractQuantity(man) {
      let newQty;
      if (this.selectQuantity[man] > 1) {
        newQty = this.selectQuantity[man] - 1;
      } else {
        newQty = 1;
      }

      this.selectQuantity = {
        ...this.selectQuantity,
        [man]: newQty,
      };
    },
    onAddQuantity(man) {
      let newQty = this.selectQuantity[man] + 1;

      this.selectQuantity = {
        ...this.selectQuantity,
        [man]: newQty,
      };
    },
    onUpdateQuantity(event, man) {
      let newQty = parseInt(event.target.value);
      if (!Number.isInteger(newQty) || newQty < 0) {
        newQty = 1;
      }

      this.selectQuantity = {
        ...this.selectQuantity,
        [man]: newQty,
      };
    },
    onSubtractQuantityCart(item) {
      if (!item.combination) {
        const index = this.cartItems[0].suppliers.findIndex((cartItem) => cartItem.id === item.articleId);
        this.cartItems[index].quantity > 0 ? this.cartItems[index].quantity-- : 0;
      } else {
        if (item.axisOnly === 'F') {
          this.cartItems[0].F.quantity > 0 ? this.cartItems[0].F.quantity-- : 0;
        } else if (item.axisOnly === 'R') {
          this.cartItems[0].R.quantity > 0 ? this.cartItems[0].R.quantity-- : 0;
        }
      }
    },
    onAddQuantityCart(item) {
      if (!item.combination) {
        const index = this.cartItems[0].suppliers.findIndex((cartItem) => cartItem.id === item.articleId);
        this.cartItems[index].quantity++;
      } else {
        if (item.axisOnly === 'F') {
          this.cartItems[0].F.quantity++;
        } else if (item.axisOnly === 'R') {
          this.cartItems[0].R.quantity++;
        }
      }
    },
    onUpdateQuantityCart (item) {
      let newQty = item.quantity;
      if (!Number.isInteger(newQty) || newQty < 0) {
        newQty = 1;
      }
      if (!item.combination) {
        const index = this.cartItems[0].suppliers.findIndex((cartItem) => cartItem.id === item.articleId);
        this.cartItems[index].quantity = newQty;
      } else {
        if (item.axisOnly === 'F') {
          this.cartItems[0].F.quantity = newQty;
        } else if (item.axisOnly === 'R') {
          this.cartItems[0].R.quantity = newQty;
        }
      }
    },
    onClickInstallationInformation(item) {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('rimconfigCarConfigurator/getCertificateTexts', {
        carTag: this.getCarTag,
        man: item.man,
        certificateNumber: item.certificate,
        language: this.$route.params.lang,
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.installationInfoPopupTitle = item.description;
          this.$refs.rimconfigInstallationInfoRef.show();
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-installation-information-found'),
            message: err,
          });
        });
    },
    getTpmsImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120);
      // .replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
    onSubtractQuantitySelectedItem() {
      let quantity = this.selectedWheels.quantity;
      if (quantity > 0) {
        this.selectedWheels.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedItem() {
      let quantity = this.selectedWheels.quantity;
      this.selectedWheels.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedItem (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedWheels.quantity || 0;

      if (!event.target.value) {
        this.selectedWheels.quantity = previousQuantity;
        return;
      }

      const max = this.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedWheels.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedWheels.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedWheels.quantity = newQuantity;
    },
    async onContinueTpmsDialog() {
      this.showContinueShoppingOrShowCartDialog = false;

      await this.$store.dispatch('rimconfigCarConfigurator/selectConfiguratorWheel', this.selectedWheels);
      this.$refs.continueToTpmsDialog.show();
    },
    continueToTpms() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag-tpms',
        params: {
          carTag: this.carData.tag,
          selectedWheel: this.selectedWheels.isCombination
            ? `${this.selectedWheels.cartItems[0].articleId}+${this.selectedWheels.cartItems[1].articleId}`
            : `${this.selectedWheels.cartItems[0].articleId}`,
        },
        query:{
          wheel: this.selectedWheels.assignIds.join('+'),
          quantity: this.selectedWheels.quantity,
          isCombination: this.selectedWheels.isCombination,
          ...this.$route.query,
        },
      }));
    },
    continueToTyre() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag-tyre',
        params: {
          carTag: this.carData.tag,
          selectedWheel: this.selectedWheels.isCombination
            ? `${this.selectedWheels.cartItems[0].articleId}+${this.selectedWheels.cartItems[1].articleId}`
            : `${this.selectedWheels.cartItems[0].articleId}`,
        },
        query:{
          wheel: this.selectedWheels.assignIds.join('+'),
          quantity: this.selectedWheels.quantity,
          isCombination: this.selectedWheels.isCombination,
          ...this.$route.query,
        },
      }));
    },
  },
};
</script>