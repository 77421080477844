<template>
  <v-dialog
    v-model="showInstallationInfo"
    max-width="771"
    rounded="lg"
  >
    <v-card class="rimconfig-installation-info">
      <v-card-title>
        <h5 class="text">
          {{ title }}
        </h5>
        <v-btn
          icon
          plain
          class="close__button"
          @click="hide"
        >
          <icon-close size="18" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          v-for="(typeKey, index) in Object.keys(certificateTexts)"
          :key="`${typeKey}${index}`"
          class="certificate-texts__type"
        >
          <div class="type">
            {{ $t(`langkey.${typeKey}`) }}
          </div>
          <div class="texts">
            <div
              v-for="(item, itemIndex) in Object.keys(certificateTexts[typeKey])"
              :key="`${item}${itemIndex}`"
            >
              <div class="label">
                {{ item }}
              </div>
              <div
                v-if="typeKey === 'wheels'"
                class="wheel-texts"
                v-html="certificateTexts[typeKey][item]"
              />
              <template v-else-if="typeKey === 'tyres'">
                <div
                  v-for="(tyreKey, tyreIndex) in Object.keys(certificateTexts[typeKey][item])"
                  :key="`${tyreKey}${tyreIndex}`"
                  class="tyre-item"
                >
                  <div class="label-tyre">
                    {{ tyreKey }}
                  </div>
                  <div
                    v-if="item == 'asCollection'"
                    class="tyre-texts"
                    v-html="certificateTexts[typeKey][item][tyreKey]"
                  />
                  <template v-else-if="item === 'byDimensions'">
                    <div
                      v-for="(dimension, dimensionIndex) in Object.keys(certificateTexts[typeKey][item][tyreKey])"
                      :key="`${dimension}${dimensionIndex}`"
                      class="dimension-item"
                    >
                      <div class="label-dimension">
                        {{ dimension }}
                      </div>
                      <div
                        class="dimension-texts"
                        v-html="certificateTexts[typeKey][item][tyreKey][dimension]"
                      />
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-installation-info__dialog.scss";
</style>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showInstallationInfo: false,
    };
  },
  computed: {
    ...mapState('rimconfigCarConfigurator', ['certificateTexts']),
  },
  methods: {
    show() {
      this.showInstallationInfo = true;
    },
    hide() {
      this.showInstallationInfo = false;
    },
  },
};
</script>