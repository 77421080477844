<template>
  <div
    ref="carThreeSixtyWrapper"
    class="threesixty__car"
  >
    <p
      v-if="error.loadCar"
      class="text-center error-msg"
    >
      {{ $t('langkey.unable-to-load-image') }}
    </p>
    <template v-else>
      <div class="car-image__container">
        <transition name="fade-loading-car360">
          <circular-loader
            v-show="isLoadingCar"
            ref="circularLoaderRef"
            :progress="loadingProgress"
            :size="50"
            :fill="circularLoaderFill"
            :animation-start-value="0.0"
            :start-angle="-1.6"
            :thickness="6"
            :show-percent="true"
            style="line-height: 0;"
            class="circular-loader"
            insert-mode="append"
            empty-fill="rgba(0, 0, 0, .1)"
          />
        </transition>
        <transition
          name="fade-car360"
          mode="in-out"
        >
          <div
            v-show="!isLoadingCar"
            :class="[
              'car-image__wrapper',
              hasOneAngle ? 'one-angle' : null,
              isLoadingSuspension[suspension] ? 'loading' : null
            ]"
          >
            <template name="fade">
              <div
                v-show="isLoadingSuspension[suspension]"
                id="spinner"
              />
            </template>
            <img
              ref="carThreeSixtyImage"
              draggable="false"
            >
            <template v-if="!hasOneAngle">
              <button
                class="ro-left__btn"
                @click="onClickRotateRight"
              >
                <img
                  :src="require(`@/assets/images/icon-rotate-${iconRotateColor}.png`)"
                  class="icon-rotate left"
                >
              </button>
              <button
                class="ro-right__btn"
                @click="onClickRotateLeft"
              >
                <img
                  :src="require(`@/assets/images/icon-rotate-${iconRotateColor}.png`)"
                  class="icon-rotate"
                >
              </button>
            </template>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/threesixty__car.scss';
</style>

<script>
import { loadImg, optimisticLoadImg } from '@/utils/preloadImage';
import VueCircle from 'vue2-circle-progress';
import { primary, primaryDark } from '@/configs/themeColor';

export default {
  components: {
    'circular-loader': VueCircle,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    imageCdn: {
      type: String,
      default: 'https://cdn.jfnet.de',
    },
    imagePrefix: {
      type: String,
      default: 'cars360',
    },
    imageCarSuspension: {
      type: Array,
      default: () => [0, 1, 2, 3, 4],
    },
    imageSuffix: {
      type: String,
      default: 'cc-t-xxxxxx.png',
    },
    threshold: {
      type: Number,
      default: 0.1,
    },
    color: {
      type: String,
      default: '',
    },
    suspension: {
      type: [Number, String],
      default: 4,
    },
    wheelSize: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 770,
    },
    height: {
      type: Number,
      default: 300,
    },
    angles: {
      type: Array,
      default: () => [],
    },
    zoomin: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    wheelLoading: {
      type: Boolean,
      default: false,
    },
    iconRotateColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDragging: true,
      carThreesixtyFramePaths: [],
      carImageFrames: {},
      mouseX: 0,
      oldMouseX: 0,
      currentFrame: 4,
      error: {
        loadCar: false,
      },
      loadingProgress: 0,
      loadedVal: 0,
      loadedFrameCnt: 0,

      isLoadingSuspension: {},

      loadingTimer: null,

      isLoadingCar: true,
      carThreeSixtyCanvas: null,
      carThreeSixtyContext: null,

      startSuspension: 0,
      isEnableOptimisticLoadSuspension: true,
    };
  },
  computed: {
    circularLoaderFill() {
      return { gradient: [primary, primaryDark] };
    },
    exactCurrentFrame() {
      return Math.abs(Math.floor(this.currentFrame % this.frameCount));
    },
    integerSuspension() {
      return Math.ceil(this.suspension);
    },
    hasOneAngle() {
      return this.frameCount === 1;
    },
    frameCount() {
      return this.angles.length;
    },
    has24Frames() {
      return this.angles.length === 24;
    },
  },
  watch: {
    isError(val) {
      this.error.loadCar = val;
    },
    suspension() {
      if (this.carImageFrames[this.integerSuspension] && this.carImageFrames[this.integerSuspension][0].src.includes(this.color)) {
        this.setCarFrame();
      }
    },
    color(newVal) {
      this.loadCar({
        color: newVal,
        suspension: this.integerSuspension,
        size: this.wheelSize,
      });
    },
    wheelSize(newVal) {
      this.loadCar({
        color: this.color,
        suspension: this.integerSuspension,
        size: newVal,
      });
    },
    exactCurrentFrame(val) {
      this.$emit('frame-change', val);
    },
  },
  created() {
    this.startSuspension = this.integerSuspension;
    this.imageCarSuspension.forEach((sus) => {
      this.isLoadingSuspension = {
        ...this.isLoadingSuspension,
        [sus]: false,
      };
    });
  },
  async mounted() {
    if (this.wheelSize) {
      this.loadCar({
        color: this.color,
        suspension: this.integerSuspension,
        size: this.wheelSize,
      });
    }
  },
  beforeDestroy() {
    this.$refs.carThreeSixtyWrapper.removeEventListener('touchstart', this.initDrag);
    this.$refs.carThreeSixtyWrapper.removeEventListener('mousedown', this.initDrag);
  },
  destroyed() {
    document.removeEventListener('touchend', this.onStopDrag);
    document.removeEventListener('mouseup', this.onStopDrag);

    document.removeEventListener('touchmove', this.onDrag);
    document.removeEventListener('mousemove', this.onDrag);
  },
  methods: {
    async loadCar({
      color = this.color,
      suspension = this.integerSuspension,
      size = this.wheelSize,
    }) {
      if (!color) {
        return;
      }

      clearInterval(this.loadingTimer);
      if (this.has24Frames) {
        this.currentFrame = 4;
      } else {
        this.currentFrame = 2;
      }
      this.$refs.circularLoaderRef.updateProgress(0);
      this.loadingProgress = 0;
      this.loadedVal = 0;
      this.isLoadingCar = true;
      this.$emit('on-load', true);
      this.error.loadCar = false;
      this.carThreesixtyFramePaths = this.getThreeSixtyImages({
        color,
        suspension,
        size,
      });

      // If has more than 1 angle
      if (this.frameCount > 1) {
        setTimeout(async () => {
          await this.initThreeSixty();
          this.$emit('frame-change', this.exactCurrentFrame);
          if (!this.zoomin && this.isEnableOptimisticLoadSuspension) {
            this.optimisticLoadSuspension({
              color,
              size,
            });
          }
        }, 1000);
      } else {
        setTimeout(async () => {
          await this.loadCarFrames([`${this.carThreesixtyFramePaths[this.exactCurrentFrame]}`]);
          this.$emit('frame-change', this.exactCurrentFrame);
          this.isLoadingCar = false;
          this.setCarFrame();
          this.$emit('on-load', false);

          if (!this.zoomin && this.isEnableOptimisticLoadSuspension) {
            this.optimisticLoadSuspension({
              color,
              size,
            });
          }
        }, 1000);
      }
    },
    onLoadFrameProgress() {
      this.loadedVal++;
      this.loadedFrameCnt = (this.loadedVal * 100) / this.frameCount;
      this.loadingProgress = this.loadedFrameCnt;
    },
    countLoadFrameProgress() {
      if (this.loadingProgress > ((this.loadedVal + 1) * 100) / this.frameCount) {
        return;
      }
      if (this.loadingProgress > 80) {
        this.loadingProgress += 0.5;
        this.handleSlowLoading();
      } else {
        this.loadingProgress += 1;
      }

      if (this.$refs.circularLoaderRef) {
        this.$refs.circularLoaderRef.updateProgress(this.loadingProgress);
      }
    },
    handleSlowLoading() {
      clearInterval(this.loadingTimer);
      this.loadingTimer = setInterval(() => {
        this.countLoadFrameProgress();
      }, 1500);
    },
    optimisticLoadSuspension({ color, size }) {
      this.isEnableOptimisticLoadSuspension = true;
      this.imageCarSuspension.forEach((suspensionVal) => {
        if (suspensionVal !== this.startSuspension) {
          this.isLoadingSuspension = {
            ...this.isLoadingSuspension,
            [suspensionVal]: true,
          };
          this.loadCarFramesSuspension(
            this.getThreeSixtyImages({
              color,
              suspension: suspensionVal,
              size,
            }),
            suspensionVal,
          );
        }
      });
    },
    loadCarFramesSuspension(framePaths, suspensionVal) {
      return optimisticLoadImg(framePaths).then((images) => {
        this.carImageFrames = {
          ...this.carImageFrames,
          [suspensionVal]: images,
        };
        this.isLoadingSuspension = {
          ...this.isLoadingSuspension,
          [suspensionVal]: false,
        };
      });
    },
    loadCarFrames(framePaths) {
      this.loadingTimer = setInterval(() => {
        this.countLoadFrameProgress();
      }, 300);
      // Preload car threesixty frames
      return loadImg(framePaths, this.onLoadFrameProgress)
        .then((result) => {
          if (framePaths.length === 1 && result.length === 1 && !result[0]) {
            clearInterval(this.loadingTimer);
            this.isLoadingCar = false;
            this.error.loadCar = true;
            return;
          }
          this.carImageFrames = {
            ...this.carImageFrames,
            [this.integerSuspension]: result,
          };
          clearInterval(this.loadingTimer);
          this.$refs.circularLoaderRef.updateProgress(100);
          this.setCarFrame();

          setTimeout(() => {
            this.isLoadingCar = false;
            this.loadingProgress = 0;
          }, 500);
        })
        .catch((err) => {
          clearInterval(this.loadingTimer);
          this.isLoadingCar = false;
          if (err === 'img404') {
            this.error.loadCar = true;
          }
        });
    },
    /**
         * Get images from our cdn
         * @param {string} color
         * color: hex color code in lowercase
         * @param {string} wheel
         * wheel: wheel name id
         * @param {string} carId
         * carId: carId from DB
         * @param {string | number} suspension
         * suspension: height value range 1-4
         * @return {string[]} 24 frames
         */
    getThreeSixtyImages({ color, suspension, size }) {
      let imagePaths = [];
      if(this.frameCount === 0) {
        return;
      }

      if (this.frameCount === 1 && this.angles[0] === '') {
        const path = this.getImageSrcByTemplate({
          image: this.src,
          angle: 90,
          color,
          suspension,
          size,
          width: this.width,
          height: this.height,
        });
        imagePaths.push(path);
      } else {
        this.angles.forEach((angle) => {
          const path = this.getImageSrcByTemplate({
            image: this.src,
            angle,
            color,
            suspension,
            size,
            width: this.width,
            height: this.height,
          });
          imagePaths.push(path);
        });
      }
      return imagePaths;
    },
    getImageSrcByTemplate({image, width, height, angle, color, suspension, size}) {
      // https://cdn.tyresnparts.com/cars360/11119-{angle}-{color}-{suspension}-itwheels_michelle_02-{size}/{width}x{height}-{orientation}-t-{backgroundColor}.{fileExtension}
      let result = image;
      result = result.replace('{color}', color);
      result = result.replace('{suspension}', suspension);
      result = result.replace('{size}', size);
      result = result.replace('{angle}', angle);
      result = result.replace('{width}', width);
      result = result.replace('{height}', height);
      result = result.replace('{orientation}', 'cc');
      result = result.replace('{backgroundColor}', 'xxxxxx');
      result = result.replace('{fileExtension}', 'png');
      return result;
    },
    setCarFrame() {
      if (this.$refs.carThreeSixtyImage) {
        this.$refs.carThreeSixtyImage.src =  this.carImageFrames[this.integerSuspension][this.exactCurrentFrame].src;
      }
    },

    // Three sixty events
    initThreeSixty() {
      // Preload car threesixty frames
      return this.loadCarFrames(this.carThreesixtyFramePaths, this.exactCurrentFrame)
        .then(() => {
          this.initListeners();
          this.isDragging = false;
          this.$emit('on-load', false);
        });
    },
    initListeners() {
      this.$refs.carThreeSixtyWrapper.addEventListener('touchstart', this.initDrag);
      this.$refs.carThreeSixtyWrapper.addEventListener('mousedown', this.initDrag);
    },
    initDrag(e) {
      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('touchend', this.onStopDrag);
      document.addEventListener('mouseup', this.onStopDrag);
    },
    onDrag(e) {
      this.isDragging = true;
      this.$refs.carThreeSixtyWrapper.style.cursor = 'grabbing';

      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      if (e.type === 'touchmove') {
        this.mouseX = e.touches[0].pageX;
      } else {
        this.mouseX = e.pageX;
      }

      if (this.mouseX < this.oldMouseX) {
        this.onDragLeft();
      } else if (this.mouseX > this.oldMouseX) {
        this.onDragRight();
      }

      setTimeout(() => {
        this.oldMouseX = this.mouseX;
      }, 100);
    },
    onStopDrag(e) {
      this.isDragging = false;
      this.$refs.carThreeSixtyWrapper.style.cursor = 'grab';

      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      document.removeEventListener('touchend', this.onStopDrag);
      document.removeEventListener('mouseup', this.onStopDrag);
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('mousemove', this.onDrag);
    },
    onDragRight() {
      if (this.frameCount === 24) {
        if (this.currentFrame <= 0) {
          this.currentFrame = this.frameCount - 0.1;
        } else if (this.currentFrame - this.threshold < 0) {
          this.currentFrame = this.frameCount - 0.1;
        } else {
          this.currentFrame -= this.threshold;
        }
      } else {
        if (this.currentFrame <= 1) {
          this.currentFrame = 0;
        } else {
          this.currentFrame -= this.threshold;
        }
      }
      this.setCarFrame();
      // this.drawImageScaled(this.carImageFrames[this.integerSuspension][this.exactCurrentFrame]);
    },
    onDragLeft() {
      if (this.frameCount === 24) {
        if (this.currentFrame >= this.frameCount - 1) {
          this.currentFrame = 0;
        } else {
          this.currentFrame += this.threshold;
        }
      } else {
        if (this.currentFrame >= this.frameCount - 1) {
          this.currentFrame = this.frameCount - 1;
        } else {
          this.currentFrame += this.threshold;
        }
      }
      this.setCarFrame();
      // this.drawImageScaled(this.carImageFrames[this.integerSuspension][this.exactCurrentFrame]);
    },
    onClickRotateLeft() {
      if (this.frameCount === 24) {
        if (this.currentFrame >= this.frameCount - 1) {
          this.currentFrame = 0;
        } else {
          this.currentFrame += 1;
        }
      } else {
        if (this.currentFrame >= this.frameCount - 1) {
          this.currentFrame = this.frameCount - 1;
        } else {
          this.currentFrame += 1;
        }
      }
      this.currentFrame = Math.floor(this.currentFrame);
      this.setCarFrame();
      // this.drawImageScaled(this.carImageFrames[this.integerSuspension][this.exactCurrentFrame]);
    },
    onClickRotateRight() {
      if (this.frameCount === 24) {
        if (this.currentFrame <= 0) {
          this.currentFrame = this.frameCount - 1;
        } else {
          this.currentFrame -= 1;
        }
      } else {
        if (this.currentFrame <= 1) {
          this.currentFrame = 0;
        } else {
          this.currentFrame -= 1;
        }
      }
      this.currentFrame = Math.floor(this.currentFrame);
      this.setCarFrame();
      // this.drawImageScaled(this.carImageFrames[this.integerSuspension][this.exactCurrentFrame]);
    },
    isTouchEvent(e) {
      return e.touches;
    },
    emitImagePath() {
      if (this.carThreesixtyFramePaths.length === 1) {
        this.$emit('get-image', this.carThreesixtyFramePaths[0]);
        return;
      }

      this.$emit('get-image', this.carThreesixtyFramePaths[1]);
    },
  },
};
</script>