import { render, staticRenderFns } from "./RimconfigWheelCard.vue?vue&type=template&id=8148f8b8"
import script from "./RimconfigWheelCard.vue?vue&type=script&lang=js"
export * from "./RimconfigWheelCard.vue?vue&type=script&lang=js"
import style0 from "./RimconfigWheelCard.vue?vue&type=style&index=0&id=8148f8b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports