<template>
  <v-dialog
    v-model="showWheelInfo"
    max-width="1200px"
  >
    <v-card class="rimconfig-wheel-info__dialog">
      <div class="wheel-info-image">
        <img
          v-if="wheelData.manufacturer"
          :src="getWheelBrandImageBySize(`https://cdn.jfnet.de/wheellogos/${wheelData.manufacturer.replace(/\s/g, '')}/{width}x{height}-{orientation}-{backgroundColor}.{fileExtension}`)"
          class="brand-img"
        >
        <threesixty-wheel
          v-if="wheelData.wheels && wheelData.wheels[0] && showWheelInfo"
          :key="wheelData.wheels[0].showroomUrlTemplate"
          :src="wheelData.wheels[0].showroomUrlTemplate"
          :size="380"
          :one-frame="!wheelData.wheels[0].showroomAngles"
          :frames="wheelData.wheels[0].showroomAngles"
          :one-frame-src="getWheelImageBySize(wheelData.wheels[0].picturePerspective)"
          one-frame-size="400x400"
        />
      </div>
      <div class="wheel-info-detail">
        <h5 class="detail-title">
          {{ wheelData.design }}
        </h5>
        <div class="detail-wrapper">
          <div class="detail-row">
            <span class="label">{{ $t('langkey.wheel-dialog-brand') }}:</span>
            <span class="value">{{ wheelData.manufacturer }}</span>
          </div>
          <div class="detail-row">
            <span class="label">{{ $t('langkey.wheel-dialog-model') }}:</span>
            <span class="value">{{ wheelData.design }}</span>
          </div>
          <div class="detail-row">
            <span class="label">{{ $t('langkey.wheel-dialog-color') }}:</span>
            <span class="value">{{ wheelData.color }}</span>
          </div>
          <div class="detail-row">
            <span class="label">{{ $t('langkey.wheel-dialog-starting-price') }}:</span>
            <span class="value">{{ wheelData.minimumDiameterPrice | price }}</span>
          </div>
        </div>
        <div class="function-wrapper">
          <v-btn
            width="150"
            outlined
            @click="onClickWheelDetail"
          >
            <span class="mr-2">{{ $t('langkey.wheel-dialog-detail') }}</span> <icon-wheel-info />
          </v-btn>
          <v-btn
            width="150"
            color="primary"
            @click="onClickCart"
          >
            <span class="mr-2">{{ $t('langkey.wheel-dialog-order') }}</span> <icon-add-to-cart color="var(--theme-white)" />
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-info__dialog.scss";
</style>

<script>
import IconWheelInfo from '@/components/icons/IconWheelInfo';
import ThreesixtyWheel from '@/components/ThreesixtyWheel';

import { getImageBySize } from '@/utils';
import { mapState } from 'vuex';

export default {
  components: {
    IconWheelInfo,
    ThreesixtyWheel,
  },
  props: {
    wheelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showWheelInfo: false,
    };
  },
  methods: {
    onShowWheelInfo() {
      this.showWheelInfo = true;
    },
    onHideWheelInfo() {
      this.showWheelInfo = false;
    },
    getWheelBrandImageBySize(image) {
      return getImageBySize(image, 200, 60);
    },
    getWheelImageBySize(image) {
      return getImageBySize(image, 200, 60);
    },
    onClickWheelDetail() {
      this.$emit('click-wheel-detail', this.wheelData);
    },
    onClickCart() {
      this.$emit('click-cart', this.wheelData);
    },
  },
};
</script>