<template>
  <div class="rimconfig-wheel-overview">
    <base-rimconfig-filter-bar
      :is-show-more="isShowAllFilter"
      has-more
      is-car-configurator
      @reset="onResetFilter"
      @show-all="onShowAllFilter"
    >
      <template #select-group>
        <div class="initial-filter-list">
          <div class="configurator-car-select-filter-list">
            <base-select-filter-dropdown
              v-for="(selectField, index) in selectFilterFields"
              :key="`select${selectField.key}${index}`"
              v-model="inputSelectFilter[selectField.filterKey]"
              :is-loading="isLoadingFilter"
              :placeholder="$t(selectField.placeholder)"
              :select-label="inputSelectFilter[selectField.filterKey].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
              :option-items="selectFilterOptions[selectField.key] || []"
              :sortable="selectField.sortable"
              @input="onInputSelectFilter"
            />
          </div>
          <transition name="page">
            <div
              v-if="isShowAllFilter"
              class="configurator-car-select-filter-list more"
            >
              <base-select-filter-dropdown
                v-for="(selectField, index) in selectFilterFieldsMore"
                :key="`select${selectField.key}${index}`"
                v-model="inputSelectFilter[selectField.filterKey]"
                :is-loading="isLoadingFilter"
                :placeholder="$t(selectField.placeholder)"
                :select-label="inputSelectFilter[selectField.filterKey].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
                :option-items="selectFilterOptions[selectField.key] || []"
                @input="onInputSelectFilter"
              />
            </div>
          </transition>
        </div>
        <div class="configurator-car-checkbox-list">
          <base-checkbox
            v-for="(property, index) in propertiesFilterFields"
            :key="`${property.key}${index}`"
            v-model="inputPropertyFilter[property.key]"
            :label-text="$t(property.label)"
            class="checkbox-item"
            reverse
            @input="onInputSelectFilter"
          />
          <template v-if="isShowAllFilter">
            <base-checkbox
              v-for="(property, index) in propertiesFilterFieldsMore"
              :key="`${property.key}${index}`"
              v-model="inputPropertyFilter[property.key]"
              :label-text="$t(property.label)"
              class="checkbox-item"
              reverse
              @input="onInputSelectFilter"
            />
          </template>
        </div>
      </template>
    </base-rimconfig-filter-bar>

    <v-container>
      <div
        v-show="wheelItems.length === 0 || isLoading"
        class="loading__wrapper"
      >
        <div class="loader">
          <v-progress-circular
            indeterminate
            color="accent"
          />
        </div>
      </div>
      <transition name="page">
        <div v-show="wheelItems.length > 0 && !isLoading">
          <RecycleScroller
            v-slot="{ item }"
            class="scroller"
            :items="wheelItems"
            :item-size="151"
            key-field="id"
            page-mode
          >
            <rimconfig-wheel-card-row
              :source="item"
            />
          </RecycleScroller>
          <div
            v-if="isNoMorePaginationResult"
            class="text-center"
          >
            {{ $t('langkey.no-more-result') }}
          </div>
        </div>
      </transition>
    </v-container>

    <rimconfig-wheel-info-dialog
      ref="rimconfigWheelInfoRef"
      :wheel-data="wheelInfoPopupData"
      @click-wheel-detail="onClickWheelDetailFromInfo"
      @click-cart="onClickCartFromInfo"
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-overview.scss";
</style>

<script>
import RimconfigWheelCardRow from '@/components/RimconfigWheelCardRow';
import RimconfigWheelInfoDialog from '@/components/RimconfigWheelInfoDialog';
import BaseRimconfigFilterBar from '@/components/BaseRimconfigFilterBar';
import { RecycleScroller } from 'vue-virtual-scroller';

import {
  selectFilterFields,
  selectFilterFieldsMore,
  propertiesFilterFields,
  propertiesFilterFieldsMore,
} from '@/pages/rims-config/configurator/_carTag/configs';
import { mapState, mapGetters } from 'vuex';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  // eslint-disable-next-line
  name: 'keep-state',
  components: {
    BaseRimconfigFilterBar,
    RimconfigWheelInfoDialog,
    RimconfigWheelCardRow,
    RecycleScroller,
  },
  data() {
    return {
      isShowAllFilter: true,

      inputSelectFilter: {},
      inputPropertyFilter: {},

      showWheelDetail: false,
      wheelInfoPopupData: {},

      wheelItems: [],
      wheelPerRow: 5,

      isLoading: false,
      isLoadingFilter: false,

      isLoadingNextPage: false,
      pagination: 1,
      isNoMorePaginationResult: false,
    };
  },
  computed: {
    ...mapState({
      selectedWheel: (state) => state.rimconfigCarConfigurator.selectedWheel,
      prevCarTag: (state) => state.rimconfigCarConfigurator.prevCarTag,
    }),
    ...mapGetters({
      getGroupedCarWheelsByDiameter: 'rimconfigCarConfigurator/getGroupedCarWheelsByDiameter',
      getCarWheelsSelectFilterOptions: 'rimconfigCarConfigurator/getCarWheelsSelectFilterOptions',
    }),
    propertiesFilterFields() {
      return propertiesFilterFields;
    },
    propertiesFilterFieldsMore() {
      return propertiesFilterFieldsMore;
    },
    selectFilterFields() {
      return selectFilterFields;
    },
    selectFilterFieldsMore() {
      return selectFilterFieldsMore;
    },
    selectFilterOptions() {
      let selectFilterFieldsAll = [
        ...this.selectFilterFields,
        ...this.selectFilterFieldsMore,
      ];
      return this.getCarWheelsSelectFilterOptions(selectFilterFieldsAll);
    },
    getCarTag() {
      return this.$route.params.carTag;
    },
    wheelRowCount() {
      return Math.ceil(this.getGroupedCarWheelsByDiameter.length / this.wheelPerRow);
    },
  },
  watch: {
    $route() {
      this.clearWheelOverview();
    },
  },
  created() {
    this.setInitInputSelectFilter();
    this.setInitInputPropertyFilter();
    if (this.getCarTag !== this.prevCarTag) {
      this.getWheelOverview();
    } else {
      this.setWheelPerRow();
    }

    this.isLoadingFilter = true;
    this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', {
      carTag: this.getCarTag,
    }).finally(() => {
      this.isLoadingFilter = false;
    });

    this.$on('onClickWheel', (id, minimumDiameterWheels) => {
      this.$emit('on-select-wheel', minimumDiameterWheels);
    });

    this.$on('onClickDiameter', (id, diameterWheels) => {
      this.$emit('on-select-wheel', diameterWheels);
    });

    this.$on('onShowWheelInfo', (id, wheelData) => {
      this.wheelInfoPopupData = wheelData;
      this.$refs.rimconfigWheelInfoRef.onShowWheelInfo();
    });

    this.$on('onClickCart', (id, wheelData) => {
      this.$emit('click-cart', wheelData);
    });
  },
  mounted() {
    window.addEventListener('scroll', this.handleCarWheelsScroll);
  },
  beforeDestroy() {
    this.$store.dispatch('rimconfigCarConfigurator/setPrevCarTag', '');
    window.removeEventListener('scroll', this.handleCarWheelsScroll);
  },
  methods: {
    handleCarWheelsScroll() {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2 - 50
        && this.isLoadingNextPage === false
        && this.isNoMorePaginationResult === false) {
        this.isLoadingNextPage = true;
        this.getWheelOverviewNextPage();
      }
    },
    onClickWheelDetailFromInfo(wheelData) {
      this.$emit('click-wheel-detail', wheelData);
    },
    onClickCartFromInfo(wheelData) {
      this.$emit('click-cart', wheelData);
    },
    setInitInputSelectFilter() {
      selectFilterFields.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.filterKey]: [],
        };
      });
      selectFilterFieldsMore.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.filterKey]: [],
        };
      });
    },
    setInitInputPropertyFilter() {
      propertiesFilterFields.forEach((item) => {
        this.inputPropertyFilter = {
          ...this.inputPropertyFilter,
          [item.key]: false,
        };
      });
      propertiesFilterFieldsMore.forEach((item) => {
        this.inputPropertyFilter = {
          ...this.inputPropertyFilter,
          [item.key]: false,
        };
      });
    },
    getWheelOverview() {
      this.isLoading = true;
      if (this.getCarTag) {
        this.pagination = 1;
        this.isNoMorePaginationResult = false;
        this.$store.dispatch('rimconfigCarConfigurator/getCarWheels', {
          carTag: this.getCarTag,
          page: this.pagination,
        })
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
            }, 200);
            this.$store.dispatch('rimconfigCarConfigurator/setPrevCarTag', this.getCarTag);
            if (res.data.length === 0) {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.no-wheels-found'),
                message: this.$t('langkey.please-select-a-new-car'),
              });
              this.$store.dispatch('rimconfigCarSelector/clearCarSelector')
                .then(() => {
                  this.$router.push(this.$i18nRoute({
                    name: 'rims-config-configurator',
                  }));
                });
            } else {
              this.setWheelPerRow();
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    getWheelOverviewNextPage() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const selectFilterPayload = this.getFilterPayload(this.inputSelectFilter);
      let propertyFilterPayload = {};
      Object.keys(this.inputPropertyFilter).forEach((propertyKey) => {
        if (this.inputPropertyFilter[propertyKey]) {
          if (propertyKey === 'stockMoreThanFour') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              minStock: 4,
            };
          } else if (propertyKey === 'alloy') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              materials: propertyFilterPayload.materials
                ? [
                  ...propertyFilterPayload.materials,
                  'alloy',
                ]
                : ['alloy'],
            };
          } else if (propertyKey === 'steel') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              materials: propertyFilterPayload.materials
                ? [
                  ...propertyFilterPayload.materials,
                  'steel',
                ]
                : ['steel'],
            };
          } else {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              [propertyKey]: true,
            };
          }
        }
      });

      if (propertyFilterPayload.materials) {
        propertyFilterPayload = {
          ...propertyFilterPayload,
          materials: propertyFilterPayload.materials.join(),
        };
      }

      this.pagination += 1;
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsNextPage', {
        ...selectFilterPayload,
        ...propertyFilterPayload,
        carTag: this.getCarTag,
        page: this.pagination,
      })
        .then((res) => {
          this.isLoadingNextPage = false;
          if (res.data.length !== 0) {
          // let mockDataLength = 0;
          // if (mockDataLength !== 0) {
            this.setWheelPerRow();
          } else {
            this.isNoMorePaginationResult = true;
          }
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    getWheelOverviewFilter(filterData) {
      if (this.getCarTag) {
        this.pagination = 1;
        this.isNoMorePaginationResult = false;
        this.$store.dispatch('rimconfigCarConfigurator/getCarWheels', {
          ...filterData,
          carTag: this.getCarTag,
          page: this.pagination,
        })
          .then((res) => {
            if (res.data.length > 0) {
              this.wheelItems = [];
              this.setWheelPerRow();
            }
            setTimeout(() => {
              this.isLoading = false;
            }, 300);
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    setWheelPerRow() {
      let rowArray = [];
      this.wheelItems = [];
      for (let row = 1; row <= this.wheelRowCount; row++) {
        const perRowItems = this.getGroupedCarWheelsByDiameter.slice((row - 1) * this.wheelPerRow, row * this.wheelPerRow);
        perRowItems.forEach((item) => {
          rowArray.push({
            ...item,
          });
        });
        this.wheelItems = [
          ...this.wheelItems,
          {
            id: `unique_${row}`,
            items: rowArray,
          },
        ];
        rowArray = [];
      }
    },
    onShowAllFilter() {
      this.isShowAllFilter = !this.isShowAllFilter;
    },
    onResetFilter() {
      this.setInitInputPropertyFilter();
      this.setInitInputSelectFilter();
      this.$store.dispatch('rimconfigCarConfigurator/setSeletedFilters', {
        inputSelectFilter: this.inputSelectFilter,
        inputPropertyFilter: this.inputPropertyFilter,
      });
      this.isLoadingFilter = true;
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', {
        carTag: this.getCarTag,
      })
        .then(() => {
          this.wheelItems = [];
          this.getWheelOverview();
        })
        .finally(() => {
          this.isLoadingFilter = false;
        });
    },
    clearWheelOverview() {
      this.$store.dispatch('rimconfigCarConfigurator/clearWheelOverview');
    },
    onInputSelectFilter() {
      const selectFilterPayload = this.getFilterPayload(this.inputSelectFilter);
      let propertyFilterPayload = {};
      Object.keys(this.inputPropertyFilter).forEach((propertyKey) => {
        if (this.inputPropertyFilter[propertyKey]) {
          if (propertyKey === 'stockMoreThanFour') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              minStock: 4,
            };
          } else if (propertyKey === 'alloy') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              materials: propertyFilterPayload.materials
                ? [
                  ...propertyFilterPayload.materials,
                  'alloy',
                ]
                : ['alloy'],
            };
          } else if (propertyKey === 'steel') {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              materials: propertyFilterPayload.materials
                ? [
                  ...propertyFilterPayload.materials,
                  'steel',
                ]
                : ['steel'],
            };
          } else {
            propertyFilterPayload = {
              ...propertyFilterPayload,
              [propertyKey]: true,
            };
          }
        }
      });

      if (propertyFilterPayload.materials) {
        propertyFilterPayload = {
          ...propertyFilterPayload,
          materials: propertyFilterPayload.materials.join(),
        };
      }
      this.$store.dispatch('rimconfigCarConfigurator/setSeletedFilters', {
        inputSelectFilter: this.inputSelectFilter,
        inputPropertyFilter: this.inputPropertyFilter,
      });
      this.isLoadingFilter = true;
      this.isLoading = true;
      this.$store.dispatch('rimconfigCarConfigurator/getCarWheelsConcatenated', {
        ...selectFilterPayload,
        ...propertyFilterPayload,
        carTag: this.getCarTag,
      })
        .then(() => {
          this.getWheelOverviewFilter({
            ...selectFilterPayload,
            ...propertyFilterPayload,
            carTag: this.getCarTag,
          });
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error getCarWheelsConcatenated',
            message: err,
          });
        })
        .finally(() => {
          this.isLoadingFilter = false;
        });
    },
    getFilterPayload(filters) {
      let payload = {};
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          payload = {
            ...payload,
            [key]: filters[key].join(),
          };
        } else if (!Array.isArray(filters[key]) && filters[key]) {
          payload = {
            ...payload,
            [key]: filters[key],
          };
        }
      });
      return payload;
    },
  },
};
</script>