<template>
  <section
    :class="[
      'rimconfig-car-simulator',
      isLoadingCarThreesixty && 'car-loading',
      isFullscreen && 'is-fullscreen'
    ]"
  >
    <div class="car-simulator-info-box">
      <div class="text-wrapper">
        <p class="car-info-title">
          {{ getCarInfoTitle }}
        </p>
        <p class="car-info-subtitle">
          {{ getCarInfoSubtitle }}
        </p>
      </div>
      <button
        class="icon-info"
        @click="onClickInfo"
      >
        <icon-info />
      </button>
    </div>
    <div class="car-simulator-background-select-box">
      <ul class="background-list">
        <li
          v-for="(item, index) in carSimulator360Bg"
          :key="`${item.key}${index}`"
          :class="['background-item', selectedBg.key === item.key && 'selected']"
          @click="onSelectBackground(item)"
        >
          <img :src="item.url">
          <template v-if="selectedBg.key === item.key">
            <icon-corner-two
              v-for="(corner, cornerIndex) in selectedBgCorners"
              :key="`${corner}${cornerIndex}`"
              :class="corner"
            />
          </template>
        </li>
        <li
          :class="['background-item', selectedBg === 'blank' && 'selected']"
          @click="onSelectBackground('blank')"
        >
          <div class="blank" />
          <template v-if="selectedBg === 'blank'">
            <icon-corner-two
              v-for="(corner, cornerIndex) in selectedBgCorners"
              :key="`${corner}${cornerIndex}`"
              :class="corner"
            />
          </template>
        </li>
      </ul>
      <icon-image class="icon__image" />
    </div>
    <button
      v-if="!isFullscreen"
      class="car-simulator-zoom-btn"
      @click="onClickZoom"
    >
      <icon-expand />
    </button>
    <button
      v-else
      class="car-zoom-exit-btn"
      @click="onClickExit"
    >
      <icon-exit />
    </button>
    <div class="car-simulator-suspension-box">
      <icon-suspension class="icon" />
      <span class="label">{{ $t('langkey.suspension') | uppercase }}</span>
      <vue-slider
        v-model="suspensionVal"
        :max="4"
        dot-size="10"
        tooltip="none"
      />
    </div>
    <div class="car-simulator-color-box">
      <ul class="color-list">
        <li
          v-for="(item, index) in carImage.colors"
          :key="`${item.hex}${index}`"
          :class="[
            'color-item',
            selectedCarColor === item.hex && 'selected'
          ]"
        >
          <span
            :class="[
              selectedCarColor === item.hex && 'selected'
            ]"
            :style="{ backgroundColor: `#${item.hex}` }"
            @click="onSelectColor(item.hex)"
          />
        </li>
      </ul>
      <icon-spray />
    </div>
    <threesixty-car
      v-if="!isLoading"
      :key="reloadCar"
      ref="carThreesixtyConfiguratorRef"
      :src="getImage360Src"
      :class="isFullscreen && 'is-fullscreen'"
      :color="selectedCarColor"
      :wheel-size="`${selectedWheel.diameter || ''}`"
      :suspension="suspensionVal"
      :angles="carImage.angles"
      :width="isFullscreen ? 1920 : 770"
      :height="isFullscreen ? 1080 : 300"
      :icon-rotate-color="getIconRotateColor"
      @frame-change="onCarThreesixtyFrameChange"
      @on-load="onCarThreesixtyLoading"
    />
    <!-- Car zoom resolution 1830x690 -->
  </section>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-car-simulator.scss";
</style>

<script>
import IconInfo from '@/components/icons/IconInfo';
import IconExpand from '@/components/icons/IconExpand';
import IconSuspension from '@/components/icons/IconSuspension';
import IconSpray from '@/components/icons/IconSpray';
import IconImage from '@/components/icons/IconImage';
import IconExit from '@/components/icons/IconExit';
import IconCornerTwo from '@/components/icons/IconCornerTwo';
import ThreesixtyCar from '@/components/ThreesixtyCar';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { mapState } from 'vuex';
import _ from 'lodash';
import { carSimulator360Bg } from '@/pages/rims-config/configurator/_carTag/configs.js';

export default {
  components: {
    IconInfo,
    IconExpand,
    IconSuspension,
    IconSpray,
    IconImage,
    IconExit,
    IconCornerTwo,
    ThreesixtyCar,
    VueSlider,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isFullscreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      suspensionVal: 0,
      selectedCarColor: '',
      isLoadingCarThreesixty: false,
      selectedBg: 'blank',
      selectedBgCorners: [
        'top-left',
        'top-right',
        'bottom-right',
        'bottom-left',
      ],
      reloadCar: 0,
      isFirstTime: true,
    };
  },
  computed: {
    ...mapState({
      carData: (state) => state.rimconfigCarConfigurator.carData,
      carImage: (state) => state.rimconfigCarConfigurator.carImage,
      selectedWheel: (state) => state.rimconfigCarConfigurator.selectedWheel,
    }),
    getCarInfoTitle() {
      if (!_.isEmpty(this.carData)) {
        return `${this.carData.manufacturer} ${this.carData.class} ${this.carData.version} ${this.carData.power}KW/${this.carData.systemPower}PS`;
      } else {
        return '';
      }
    },
    getCarInfoSubtitle() {
      if (!_.isEmpty(this.carData)) {
        return `${this.carData.typeApproval} ${this.carData.hsn} / ${this.carData.tsn} / ${this.carData.vsn}`;
      } else {
        return '';
      }
    },
    getImage360Src() {
      if (!_.isEmpty(this.carImage)) {
        const imageCarLabel = this.carImage.urlTemplate;
        return imageCarLabel;
      } else {
        return '';
      }
    },
    carSimulator360Bg() {
      return carSimulator360Bg;
    },
    getIconRotateColor() {
      return this.selectedBg === 'blank' ? 'dark' : this.selectedBg.iconColor;
    },
  },
  watch: {
    carImage(val) {
      if (this.isFirstTime) {
        this.selectedCarColor = val.colors[0].hex;
        this.$emit('on-select-color', this.selectedCarColor);
        this.isFirstTime = false;
      }
    },
    suspensionVal(val) {
      this.$emit('on-select-suspension', val);
    },
    isFullscreen() {
      this.reloadCar++;
    },
  },
  methods: {
    onClickInfo() {
      this.$emit('on-click-info');
    },
    onClickZoom() {
      this.$emit('on-click-zoom');
    },
    onClickExit() {
      this.$emit('on-click-exit');
    },
    onSelectColor(color) {
      this.selectedCarColor = color;
      this.$emit('on-select-color', this.selectedCarColor);
    },
    onCarThreesixtyLoading(status) {
      if (status) {
        this.isLoadingCarThreesixty = status;
        this.$emit('loading-car-threesixty', this.isLoadingCarThreesixty);

      } else {
        setTimeout(() => {
          this.isLoadingCarThreesixty = status;
          this.$emit('loading-car-threesixty', this.isLoadingCarThreesixty);
        }, 300);
      }
    },
    onCarThreesixtyFrameChange(e) {
      this.$emit('frame-change', e);
    },
    onSelectBackground(item) {
      this.selectedBg = item;
      this.$emit('on-select-background', item);
    },
  },
};
</script>