<template>
  <div class="rimconfig-wheel-card">
    <div
      class="wheel-image-section"
      @click="onSelectWheel"
    >
      <icon-winter
        v-if="currentWheel.winter"
        class="icon-winter"
        color="var(--theme-gray-4)"
      />

      <icon-three-sixty
        v-if="isWheelThreesixty"
        class="three-sixty__icon"
      />

      <img
        :key="currentWheel.picturePerspective"
        v-lazy="getWheelImageBySize(currentWheel.picturePerspective)"
        class="wheel-image"
      >
    </div>
    <div class="wheel-detail-section">
      <div class="brand-img-container">
        <img
          v-lazy="getWheelBrandImageBySize(`https://cdn.jfnet.de/wheellogos/${wheelData.manufacturer.replace(/\s/g, '')}/{width}x{height}-{orientation}-{backgroundColor}.{fileExtension}`)"
          class="brand-img"
        >
      </div>
      <div class="content-area">
        <h5 class="wheel-design">
          {{ wheelData.design }}
        </h5>
        <p class="wheel-color">
          {{ wheelData.color }}
        </p>
        <div class="price-section">
          <span v-if="priceType === 'retailTaxPrice'">
            {{ $t('langkey.brutto') }}:
          </span>
          <span v-else-if="priceType === 'retailPrice'">
            {{ $t('langkey.netto') }}:
          </span>
          <span v-else>
            {{ $t('langkey.best-price-rim-config') }}:
          </span>
          <span v-if="selectedWheel.diameter && isSelectedWheelAndDiameter(wheelData.possibleDiameters[`${selectedWheel.diameter}`])">
            {{ wheelData.diameterPriceObject[selectedWheel.diameter][priceType] | price }}
          </span>
          <span v-else>
            {{ wheelData.minimumDiameterPrice[priceType] | price }}
          </span>
        </div>
      </div>
    </div>
    <div class="wheel-diameter-section">
      <div
        v-for="(diameter, index) in Object.keys(wheelData.possibleDiameters)"
        :key="`wheelCardDiameter${diameter}${index}`"
        :class="[
          'diameter-item',
          isSelectedWheelAndDiameter(wheelData.possibleDiameters[`${diameter}`])
            ? 'selected'
            : null
        ]"
        @click="onSelectDiameter(diameter)"
      >
        {{ diameter }}
      </div>
    </div>
    <div class="wheel-function-section">
      <button
        class="function-button wheel-info"
        @click="onClickInfo"
      >
        <icon-wheel-info />
      </button>
      <button
        class="function-button"
        @click="onClickCart"
      >
        <icon-add-to-cart height="14" />
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-card.scss";
</style>

<script>
import IconWheelInfo from '@/components/icons/IconWheelInfo';
import IconWinter from '@/components/icons/IconWinter';
import IconThreeSixty from '@/components/icons/IconThreeSixty';
import { getImageBySize } from '@/utils';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  components: {
    IconWheelInfo,
    IconWinter,
    IconThreeSixty,
  },
  props: {
    wheelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentWheel: {},
    };
  },
  computed: {
    ...mapState({
      selectedWheel: (state) => state.rimconfigCarConfigurator.selectedWheel,
    }),
    ...mapState('pricing', ['priceType']),
    isWheelThreesixty() {
      return Array.isArray(this.currentWheel.showroomAngles) && this.currentWheel.showroomAngles.length > 0 && this.currentWheel.showroomUrlTemplate;
    },
  },
  created() {
    const minimumDiameterWheels = Object.values(this.wheelData.possibleDiameters)[0];
    this.currentWheel = minimumDiameterWheels[0];
  },
  methods: {
    getWheelBrandImageBySize(image) {
      return getImageBySize(image, 100, 30);
    },
    getWheelImageBySize(image) {
      return getImageBySize(image, 100, 100);
    },
    onSelectWheel() {
      const minimumDiameterWheels = Object.values(this.wheelData.possibleDiameters)[0];
      this.currentWheel = minimumDiameterWheels[0];
      this.$emit('click-wheel', minimumDiameterWheels);
    },
    onSelectDiameter(diameter) {
      const diameterWheels = this.wheelData.possibleDiameters[diameter];
      this.currentWheel = diameterWheels[0];
      this.$emit('click-diameter', diameterWheels);
    },
    onClickInfo() {
      this.$emit('click-info', this.wheelData);
    },
    onClickCart() {
      this.$emit('click-cart', this.wheelData);
    },
    isSelectedWheelAndDiameter(wheels) {
      if (!wheels) {
        return false;
      }
      const result = wheels[0].wheelTag === this.selectedWheel.wheelTag &&
        wheels[0].diameter === this.selectedWheel.diameter;
      return result;
    },
  },
};
</script>